.content main {

  & .content__row {
    --row-max-width: 1280px;
  }

  & a {
    color: var(--red);
  }

  & p {
    color: var(--grey-text);
    line-height: 1.8;
  }

  & b, & strong {
    color: var(--dark);
  }

  & #retoureBtn {
    margin-top: 20px;
    color: var(--dark);
    border-color: var(--dark);
    &[style*=block] {
      display: inline-flex !important;
    }
  }

  & .ul-list, & ul[style*=square] {
    font-weight: 300;
    line-height: 34px;
    padding-left: 25px;
    margin-left: 0 !important;

    & li {
      list-style-type: disc;
    }

    & li::marker {
      color: var(--red);
    }
  }

  & .payment {

    & .payment__table--scroll {
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
    }

    & .payment__table {
      width: 100%;
      background: white;
      border: 1px solid #ddd;

      & th, & td {
        padding: 14px;
      }

      & tr:nth-of-type(2n) {
        background: #eceaea;
      }

      & th {
        background: #6C9680;
        color: #fff;
        line-height: 18px;
        text-transform: uppercase;

        &:first-child {
          &, & + th {
            text-align: left;
          }
        }
      }

      .w50 {
        min-width: 50px;
      }
    }
  }

  & .shipping {

    & #shipping__tabs {

      & ul {
        margin-bottom: 20px;
        display: flex;

        & a {
          margin-inline-end: 20px;
        }
      }
    }

    &	table {
      border-collapse: collapse;
      min-width: 460px;
    }

    & td {
      min-width: 80px;
    }

    & table, & th, & td {
      text-align: left;
      padding: 5px;
      border: 1px solid #ccc;
    }

    & th {
      background: var(--grey);

    }

    & .shipping__table {
      overflow: hidden;
      overflow-x: auto;
      padding-bottom: 10px;
    }
  }


   & #wagluamanuals {
    & a {
      color: inherit;
      text-decoration: none;
    }
  }

  & #wafaq {

    & h2 {
      margin-top: 40px;
      color: var(--red);
    }

    & h4 {
      position: relative;
      border-bottom: 1px solid var(--grey);
      padding-bottom: 21px;

      & span:nth-of-type(2) {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 7px;
        bottom: 0;
        display: flex;
        z-index: 3;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: auto;

        &::before, &::after {
          display: block;
          content: "";
          background-color: currentcolor;
          transition: .5s ease .1s;
        }

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 12px;
          height: 2px;
          margin: auto;
        }

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 2px;
          height: 12px;
          margin: auto;
        }
      }

      & + p {
        display: none;
      }


      &.is-open {
        & span:nth-of-type(2)::after {
          opacity: 0;
          height: 0;
        }

        & + p {
          display: block;
        }
      }
    }
  }

  & .visualcms {
    & h1, & .headline__text {
      text-align: center;
    }

    & .owl-nav:not(.disabled) {
      opacity: 1;
      visibility: visible;
      transform: translateY(-50%);

    }
  }

}