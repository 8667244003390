.start {
  & .start__row {
    --row-max-width: 1640px;
    overflow: hidden;
  }

  & .start__header {
    overflow: hidden;

    @media (width > 780px) {
      margin-top: calc(var(--block-grid-space) * 2);
    }

    /* header slider */

    & .start__header--item {
      position: relative;

      @media (width <= 780px) {
        height: 460px;

        & > img {
          max-width: none;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & .caption {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10;
        bottom: 10%;
        left: 10%;
        right: 10%;

        & h3 {
          font-size: 50px;
          font-family: 'Oswald', sans-serif;
          font-weight: 500;
          color: white;
          text-shadow: 0.0625rem 0.0625rem 0.125rem #0a0a0a;
          margin-bottom: 10%;
          @media (width > 780px) {
            font-size: 60px;
          }
        }

        & p {
          font-size: 13px;
          padding: 5px;
          color: var(--dark);
          font-weight: 400;
          line-height: 20px;
          margin-top: 12px;
          margin-bottom: 32px;
          background-color: rgba(255, 255, 255, .75);


          @media (width > 780px) {
            font-size: 18px;
            padding: 10px;
            margin-top: 32px;
            line-height: 30px;
          }
        }
      }
    }


    /* header tiles */

    & .start__header--tiles {
      margin-top: calc(var(--block-grid-space) * 2);
      padding-left: var(--grid-space);
      padding-right: var(--grid-space);

      @media (width > 780px) {
        margin-top: 0;
        margin-bottom: 5%;
        padding: 0;
      }

      & li {
        @media (width > 780px) {
          margin-top: 0;
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
          @media (width > 780px) {
            margin-bottom: 5%;
          }
        }
      }

      & > li > a > div:nth-of-type(2) {
        @media (width <= 780px) {
          padding: 14px 0;
          margin-top: 0;
        }
      }

      & h4 {
        @media (width <= 780px) {
          font-size: 16px;
        }
      }

      & p {
        @media (width <= 780px) {
          display: none;
        }
      }
    }
  }

  /* preface */

  & .start__preface {
    padding: 10px;

    @media (width > 780px) {
      margin: 10px auto;
    }


    & p {
      padding: 20px 10px;
      color: var(--grey-text);
      line-height: 1.8;
      font-size: 18px;
      text-align: center;

      @media (width <= 780px) {
        padding: 0 10px 20px 10px;
        font-weight: 300;
        font-size: 16px;
      }
    }
  }


  /* top categories */

  & .start__tile {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--dark);

    & h4 {
      text-align: center;
      margin: 0;
      font-size: 20px;
      font-weight: 500;
    }

    & p {
      text-align: center;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.8;
      color: var(--grey-text);
      flex-grow: 1;
    }

    & > div {
      display: flex;
      flex-direction: column;


      &:nth-of-type(2) {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: 25px;
        background-color: white;
        margin-top: -75px;
        margin-right: 22px;
        margin-left: 22px;
        position: relative;
        z-index: 3;
      }
    }
  }

  /* collection */

  & .start__collection {
    margin: 30px 0 70px 0;
    background-repeat: no-repeat;

    @media (width > 780px) {
      margin: 100px 0;
    }

    & .grid-column {
      &.left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;

          & p {
            color: white;
            text-shadow: 0.0625rem 0.0625rem 0.125rem #0a0a0a;

            &:nth-of-type(1) {
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 3px;
              text-transform: uppercase;
              margin-bottom: 10px;
              margin-top: 30px;
              line-height: 1.8;

              @media (width > 780px) {
                font-size: 24px;
              }
            }

            &:nth-of-type(2) {
              font-size: 30px;
              font-weight: 400;
              text-transform: uppercase;
              margin-top: 0;
              margin-bottom: 35px;
              line-height: 1.2;
              font-family: Oswald, sans-serif;

              @media (width > 780px) {
                font-size: 60px;
              }
            }
          }
        }
      }

      &.right {
        padding-top: 60px;
        padding-bottom: 30px;
      }
    }

    & .start__collection-item {
      margin-bottom: 30px;
      display: flex;
      background-color: white;
      max-width: 90%;
      margin-left: 5%;

      @media (width > 780px) {
        max-width: 82%;
        margin-left: 30px;
      }

      & img, & a {
        color: var(--black);
        display: block;
        text-decoration: orange;
      }

      & > div {
        width: 100%;
        position: relative;
        padding: 10px;


        @media (width > 780px) {
          padding: 18px 25px 15px;
        }

        & > h3 {
          font-size: 14px;
          font-weight: 500;
          margin: 0;

          @media (width > 780px) {
            font-size: 18px;
          }
        }

        & > p {
          color: var(--grey-text);
        }

        & .tooltip {
          position: absolute;
          right: 25px;
          top: 18px;
          cursor: pointer;
          display: none;

          &:hover {
            color: var(--red);
          }
        }
      }
    }
  }

  /* blog styles */

  & .start__blog {
    margin-bottom: 100px;

    & .start__blog--item {
      margin-bottom: 10px;

      & > a {
        position: relative;
        display: block;
        text-decoration: none;
        color: var(--black);

        & > img {
          width: 100%;
        }
      }

      & .start__blog--content {

        &.left {
          @media (width > 780px) {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            padding: 37px;
            background-color: rgba(255, 255, 255, .65);
          }

          & h6, & p, & small {
            color: (--dark);
          }
        }

        & h6 {
          font-size: 25px;
          line-height: 35px;
          margin: 0;
          font-weight: 400;
          font-family: Poppins, sans-serif;
        }

        & p {
          color: white;
          background-color: var(--red);
          padding: 4px 10px;
          border-radius: 2px;
          line-height: 20px;
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 10px;
          max-width: max-content;
          text-transform: uppercase;
        }

        & h6 {
          --transition-time: .2s;

          &:hover {
            color: var(--red);
          }
        }
      }
    }
  }

  /* recos tabs */

  & .start__recommendations {

    & .owl-carousel {
      width: auto;
    }

    & > #tabs {
      & > div:nth-of-type(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        flex-direction: column;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--grey);
        padding-bottom: 14px;
        margin-top: 20px;

        @media (width > 780px) {
          flex-direction: row;
          align-items: baseline;
          justify-content: space-between;
          padding-bottom: 0;
          margin-top: 0;
        }

        & h3 {
          font-family: Roboto Slab, sans-serif;
          font-size: 35px;
          line-height: 42px;
          font-weight: 400;
          margin-bottom: 10px;
          margin-top: 0;
        }
      }

      & .nav-tabs {
        display: flex;

        & a {
          display: block;
          font-family: Roboto Slab, sans-serif;
          font-size: 16px;
          font-weight: 400;
          text-decoration: none;
          color: var(--dark);
          padding: 10px;

          @media (width > 780px) {
            padding: 0 0 0 20px;
          }

          &.active {
            color: var(--red);
          }
        }
      }

      & .recommendations {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }
}