.search {

  &:not(.is-open) .search__popup {
    top: 110% !important;
  }

  &.is-open .search__popup {
    pointer-events: auto;
    opacity: 1;
  }

  & .search__popup {
    position: fixed;
    background-color: white;
    z-index: 200;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    overflow-x: auto;
    scrollbar-width: thin;
    padding: 7px 10px 100px 10px;

    & h2 {
      font-family: "Roboto Slab", sans-serif;
      font-weight: 500;

      @media (width <= 780px) {
        margin-bottom: 0;
      }
    }

    & button {
      position: absolute;
      right: 15px;
      top: 15px;
      background-color: white;
    }

    & .search__popup--close {
      position: absolute;
      top: 15px;
      right: 20px;
      color: var(--red);
    }


    &:has(#makaira-search.show) .search__popup--header {
      @media (width <= 780px) {
         display: none;
      }
    }
  }

  & .search__hint--headline {
    & .red {
      color: var(--red);
    }
  }

  & .search__hint  {
   & li {
     text-align: center;
   }
  }
}