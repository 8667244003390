.calculator {
  & .calculator__button {
    display: flex;
    align-items: center;
    background: rgba(var(--red-rgb), .75);
    color: white;
    margin-top: 10px;
    max-width: max-content;
    border-radius: 3px;
    font-size: 13px;
    padding: 9px 8px;
    line-height: 1;

    @media (width > 780px) {
      padding: 10px 13px;
      font-size: 14px;
    }

    &:hover {
      background: rgba(var(--red-rgb), 1);
    }

    & img {
      margin-right: 7px;
    }
  }

  & .calculator__modal {

    @media (width <= 780px) {
      & > ul {
        & > li:nth-of-type(2) {
          order: -1;
          margin-bottom: 20px;
        }
      }
    }


    & h4 {
      margin: 0 0 10px 0;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--grey);
      font-weight: 500;
    }

    & ol {
      list-style: none;
      counter-reset: counter;

      & li {
        color: var(--grey-text);
        counter-increment: counter;
        margin-bottom: 10px;

        &::before {
          content: counter(counter) ". ";
        }
      }
    }

    & p {
      color: var(--grey-text);
    }

    & input, & button {
      border-radius: 3px;
      height: 42px;
      min-height: 42px;
      max-height: 42px;
    }

    & [type="number"] {
      max-width: 100%;
      padding: 0 12px;
      font-size: 16px;
      border: 1px solid #ddd;
      background-color: var(--light-grey);
    }

    & b {
      font-size: 14px;
      font-weight: 500;
    }

    & .calculator__output {
      border-radius: 3px;
      background-color: var(--grey);
      padding: 14px 10px;
      margin-top: 21px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}