.custom {
  position: relative;
  overflow: hidden;

  & #customGenerateMessage,
  & #customPromtMessage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.35);
    justify-content: center;
    align-items: center;
    z-index: 100000;
    display: none;

    &[style*=block] {
      display: flex !important;
    }

    & > div {
      padding: 21px;
      background-color: white;
      border: 1px solid #444;
      text-align: center;
      max-width: 480px;
      max-height: 90%;

      & > h4 {
        text-align: center;
        font-weight: 400;
      }
    }
  }


  & .custom__button {
    display: inline-flex;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;
    color: white;
    padding-right: 14px;
    max-height: 38px;
    overflow: hidden;

    &[style] + .custom__tabs {
      display: block;
    }

    & > div {
      margin-right: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 38px;
      background-color: var(--red);
      position: relative;
      box-shadow: 1px 0 0 0 #999;

      & img {
        width: 24px;
      }

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent var(--red);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(5px, -50%);
      }
    }
  }

  & .custom__stage {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    overflow: hidden;
    @media (width <= 480px)  {
      max-height: 60vh;
      transform: translateY(-36%);
      overflow: visible;
    }

    & #canvas-container {
      display: inline-flex;
      border: 1px solid var(--grey);
    }
  }

  & [id^=bounding] {
    height: 0;
    width: 100%;
    background-color: rgba(000, 000, 000, .5);
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    z-index: 100;
    color: white;

    &#boundingTop {
      top: 0;
    }

    &#boundingBottom {
      bottom: 0;
    }
  }

  & .custom__tabs {
    display: none;

    & .custom__tabs--list {
      display: flex;
      align-items: flex-end;

      & div[id] {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 7px 14px;
        border: solid #3d3c3e;
        border-width: 3px 1px 0 1px;
        user-select: none;

        &.active {
          background-color: #3d3c3e;
          padding: 10px 14px;
          color: white;
        }
      }
    }

    & .custom__tabs--content {
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid #3d3c3e;
    }

    & .custom__icon {
      cursor: pointer;
    }

  }

  & [data-control] {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
    cursor: pointer;
    user-select: none;

    &:not(:nth-last-of-type(1)) {
      border-bottom: 1px solid var(--dark);
    }

    &[data-onactive]:not(.show),
    &[data-inactive]:not(.show) {
      opacity: .25;
      pointer-events: none;
    }

    & [type=color] {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      padding: 0;
      cursor: pointer;
      opacity: 0;
    }

    & span {
      text-align: center;
      margin-top: 3px;
    }

    & span[class^=material] {
      cursor: pointer;
      margin: 3px 0;
      font-size: 24px;
    }

    & span:not([class]) {
      font-size: 11px;
    }

    &#forward span[class^=material] {
      transform: rotate(-90deg);
    }

    &#backward span[class^=material] {
      transform: rotate(90deg);
    }

    &#done {
      @media (width <= 780px)  {
        margin: 0;
      }
    }
  }

  & #layerControls {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: column;
      padding: 7px 7px 0 7px;
      border: 1px solid transparent;
      color: var(--dark);


      &:hover {
        border-color: #ffffff80;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  & fieldset {
    background-color: #efefef;
    border: 1px solid #ccc;
    margin-bottom: 14px;

    & legend {
      padding: 0 7px;
      font-weight: 600;
    }
  }

  & #textRemove {
    & label {
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
      &::before {
        position: relative;
        top: 4px;
      }
    }
  }

  & #textControls {
    display: block;
    position: static;
    top: 0;
    left: 0;
    z-index: 1;

    & .custom__text {

      &[data-onactive]:not(.show) {
        opacity: .25;
        pointer-events: none;
      }

      & small {
        display: flex;
        font-size: 13px;
        justify-content: space-between;

        &.bold {
          font-size: 15px;
        }
      }

      & > div {
        display: flex;
        flex-direction: column;
        margin: 7px 0;
      }

      & input, & select, & textarea {
        background-color: white;
        padding: 7px;
      }

      & textarea {
        height: auto;
        overflow: hidden;
        font-size: 18px;
      }
      & input, & select {
        max-height: 32px;
      }

      & [type=color] {
        min-width: 25%;
        min-height: 32px;
        padding: 0 2px;
        cursor: pointer;
      }

      & [type=number] {
        max-width: 25%;
      }

      & .custom__text--direction {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & li {
          display: flex;
          align-items: flex-end;
          user-select: none;
          overflow: hidden;
          cursor: pointer;

          &.active, &:hover {
            color: #777;
          }

          &:not(:last-of-type) {
            border-right: 1px solid var(--dark);
          }

        }
      }

      & .custom__options {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        & > div {
          display: flex;
          align-items: center;
          & svg {
            margin-right: 3px;
            position: relative;
            top: -1px;
            transform: rotate(-180deg);
          }
        }
        &.open {
          display: none;
        }
        &:not(.open) + ul {
          display: none;
        }
      }
    }
  }

  & .custom__icons {
    padding: 7px;

    & li  {
      padding-bottom: 5px;
      margin-bottom: 5px;
      position: relative;
      text-align: center;

      & input,
      & img {
        cursor: pointer;
        max-width: 100%;
        padding: 0;
      }
    }

    & .custom__icon--break {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;

      & hr {
        background: var(--grey-text);
        height: 1px;
        border: none;
      }
    }

  }

  /*map styles*/

  & #map {
    width: 600px;
    height: 570px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background-size: 60px;
  }

  & #mapInput {
    font-size: 16px;
    padding-left: 42px;
    & + svg {
      position: absolute;
      top: 10px;
      left: 12px;
    }
  }

  & #mapInputResults:not(:empty) {
    border: solid #777;
    border-width:  0 1px 1px 1px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: white;

    & > li {
      border-bottom: 1px solid #b4b8b8;
      font-size: 14px;
      padding: 9px 10px;
      cursor: pointer;
    }
  }

  & #mapLayout {
    margin: 12px 0;
    display: flex;
    flex-wrap: wrap;
    & li {
      max-width: 11.1%;
      & img {
        border-radius: 7px;
        border: 1px solid rgba(255, 255, 255, .5);
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }


  & .leaflet-control-container {
    display: none;
  }

}


