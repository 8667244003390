.order {

  & .order__row {
    --row-max-width: 1400px;
  }

  & .order__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 14px 0 14px;
    margin-bottom: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120% auto;
    font-family: Poppins, sans-serif;

    & h1 {
      margin: 0;
      font-size: 7vw;
      font-weight: 500;
      text-align: center;
      font-family: Poppins, sans-serif;

      @media (width > 480px) {
        font-size: 32px;
      }
    }

    &[style*="background-image"] {
      padding-top: 60px;
      padding-bottom: 60px;
      border-bottom: 1px solid var(--grey);

      & h1, & .breadcrumb * {
        color: white;
      }
    }
  }

  & .grid-column {
    &.left {
      @media (width > 1280px) {
        padding-right: 40px;
        flex: 1 1 50%;
        max-width: 50%;
      }
    }

    &.right {
      background-color: var(--light-grey);
      padding: 20px;
      @media (width > 1280px) {
        flex: 1 1 50%;
        max-width: 50%;
      }
    }
  }

  & #orderAddress,
  & #orderAddress + .grid-row {
    & .card {
      padding: 14px 10px 14px 20px;
      background-color: white;
      border: 1px solid #d4d4d4;
      margin-bottom: 14px;

      @media (width > 780px) {
        margin-bottom: 20px;
      }

      & strong + br + br {
        display: none;
      }

      & .card-title {
        margin-top: 0;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        & button {
          cursor: pointer;
          font-size: 13px;
          background-color: transparent;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    & .card-body {
      font-weight: 300;
    }
  }

  & .basket__footer {
    margin-top: 0;
    padding: 0;
    background: white;
  }

  & .agb.card {
    & .card-header {
      display: none;
    }
  }

  & .agbConfirmation, & .agbConfirmation + .dd-ve-container {
    max-width: 475px;
    text-align: right;
    font-size: 10px;
    color: var(--grey-text);

    & a {
      text-decoration: underline;
      color: inherit;
    }
  }

  & .cart-buttons {
    & .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    & [type=submit] {
      min-width: 100%;
      padding: 20px;
      justify-content: center;
      font-size: 20px;
      margin-top: 21px;
    }
  }

  & .checkout__box {

    & ~ .basket__row {
      display: none;
    }

    & fieldset {
      border: 1px solid var(--grey);
    }

    & legend {
      padding: 0 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      color: var(--dark);
      text-transform: uppercase;
      letter-spacing: 2.5px;
      border: 0;
    }
  }

  & .basket__sidebar {
    margin-left: 0;
    padding: 0;
  }
}