.flyout  {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  pointer-events: none;


  & .flyout__bg {
    --transition-prop: ease-in-out;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    animation: none;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
  }

  & > div:nth-of-type(2) {
    --left: 0;
    --right: auto;
    position: fixed;
    top: 0;
    left: var(--left);
    right: var(--right);
    display: flex;
    flex-grow: 1;
    z-index: 469;
    flex-direction: column;
    width: 420px;
    max-width: 84%;
    height: 100%;
    box-shadow: none;
    visibility: hidden;
    background: white;
    opacity: 0;
    transform: translate3d(-104%,0,0);
    transition: transform .5s cubic-bezier(.645, .045, .355, 1),visibility .5s cubic-bezier(.645, .045, .355, 1);
    overflow: hidden;
    pointer-events: none;
    touch-action: manipulation;
  }

  &.is-open {
    visibility: visible;
    opacity: 1;
    transition: opacity .25s, visibility;
    pointer-events: auto;

    & .flyout__bg,
    & > div:nth-of-type(2) {
      visibility: visible;
      opacity: 1;
      transform: none;
      pointer-events: auto;
    }
  }

  &#basketFlyout .flyout__header {
    background-color: var(--dark);
  }

  & .flyout__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0;
    border-bottom: 1px solid rgba(129,129,129,.2);
    padding-inline-start: 50px;
    background-color: var(--red);


    & > span {
      &:nth-of-type(1) {
        flex: 1 1 auto;
        text-transform: uppercase;
        letter-spacing: 2.38px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: white;
      }
      &:nth-of-type(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        color: white;
      }
    }
  }

  & .flyout__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--grey);
  }
}
