.tooltip {
  position: relative;

  & [data-tooltip] {
    --transition-time: .2s;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -90%);
    display: flex;
    white-space: nowrap;
    background-color: var(--red);
    font-size: 12px;
    font-weight: 400;
    color: white;
    padding: 3px 6px;
    letter-spacing: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &:hover [data-tooltip] {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -140%);

    &::before {
      display: flex;
      content: "";
      width: 6px;
      height: 6px;
      background-color: var(--red);
      position: absolute;
      bottom: -3px;
      left: calc(50% - 3px);
      transform: rotate(45deg);
      transform-origin: center;
      z-index: -1;
    }
  }
}