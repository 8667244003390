.review {

  & .review__page {

    & a {
      color: inherit;
      text-decoration: none;
    }

    & .review__pictures--add {
      display: none;
      margin-top: var(--block-grid-space);
      font-size: 13px;

      &:hover {
        text-decoration: underline;
      }
    }

    & .rating {
      height: 26px;
      position: relative;
      background: url("/out/wallart-dm/img/icons/stars-grey.svg") no-repeat left top;
      background-size: 150px 26px;
      max-width: max-content;

      & li {
        &:not(.currentRate) {
          position: relative;
          z-index: 2;
          display: inline-block;
          width: 26px;
          height: 26px;
          cursor: pointer;

          & a {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        &.currentRate {
          position: absolute;
          z-index: 1;
          width: 0;
          height: 26px;
          background: url("/out/wallart-dm/img/icons/stars-yellow.svg") left bottom;
          background-size: 150px 26px;
        }
      }
    }

    & #reviewSave {
      border: 1px solid var(--dark);
      color: var(--dark);
    }
  }
}