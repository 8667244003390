.makaira {
  /* flyout styles*/

  & .makaira-filter {
    position: relative;

    & .makaira__button--bar {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: sticky;
      top: 0;
      background-color: white;
      box-shadow: 0 0 7px rgba(0, 0, 0, .3);
      z-index: 10;

      & svg {
        --transition-time: .1s;
      }

      & > * {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 15px 30px;
        font-size: 14px;
        font-weight: 600;
        color: var(--dark);
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: var(--red);
        }
      }

      & button {
        border: 0;
        background-color: transparent;

        &:hover svg {
          transform: translateX(3px);
        }
      }

      & .makaira-filter__close {
        border-right: 1px solid var(--grey);

        @media (width > 780px) {
          display: none;
        }


        & svg {
          transform: rotate(-180deg);
          position: relative;
          top: -1px;
        }

        &:hover svg {
          transform: rotate(-180deg) translateX(3px);
        }

      }
    }

    & .filter__fields {
      padding: 0 30px 70px 30px;
    }

    & .makaira-filter__filter {
      border-bottom: solid 1px #eee;
      padding-bottom: 14px;
      cursor: pointer;

      &.open .makaira-filter__filter-headline > svg {
        transform: rotate(-180deg);
      }

      &:not(.open) {
        padding-bottom: 0;

        & > div {
          height: 0;
          overflow-x: hidden;
        }
      }
    }

    & .makaira-filter__filter-headline {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 2.7px;
      text-transform: uppercase;
      margin: 22px 0;
      font-family: 'Roboto Slab', sans-serif;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .makaira-filter__list--ul {
      max-height: 405px;
      overflow-x: hidden;
      scrollbar-color: #ddd transparent;
      scrollbar-width: thin;
      padding-right: 14px;
    }

    & .makaira-filter__item {
      position: relative;
      line-height: 20px;
      margin-bottom: 15px;
      display: flex;

      & input {
        margin-right: 10px;
        position: relative;
        top: -1px;
      }

      & input:checked + label span {
        color: var(--red);
      }

      & label {
        --transition-time: .001s;
        width: 100%;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        color: var(--grey-text);

        &:hover span {
          color: var(--red);
        }

        & span {
          flex-grow: 1;
        }

        & small {
          font-size: 13px;
          opacity: .6;
        }
      }
    }
  }
  & .makaira__filter--button {
    min-height: 40px;
  }
}

/* autosuggesst */
#makaira-search {

  &:not(:empty) ~ .recommendations {
    display: none;
  }

  & .makaira-autosuggestion__list {
    @media (width > 780px) {
      display: flex;
    }

    & a {
      text-decoration: none;
      color: inherit;
    }
  }

  & .makaira-autosuggestion__list--left {
    display: flex;
    flex-wrap: wrap;
    min-width: 300px;

    @media (width > 780px) {
      flex-direction: column;
    }

    & > div {
      width: 50%;
      @media (width > 780px) {
        width: 100%;
      }
    }

    & li {
      list-style-type: disc;
      margin: 3px 20px;
      line-height: 1.8;

      &::marker {
        color: var(--red);
      }
    }
  }

  & .makaira-autosuggestion__list--right {
    width: 100%;
  }

  & .makaira-autosuggestion__list-item--header {
    font-family: "Roboto Slab", sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin: 14px 0 7px 0;
  }

  & .makaira-autosuggestion__products {
    display: flex;
    flex-flow: nowrap;
    overflow-y: hidden;
    overflow-x: hidden;
    max-width: 312.5rem;
    margin-top: 14px;

  }

  & .makaira-autosuggestion__all {
    display: flex;
    justify-content: flex-end;

    @media (width <= 480px) {
      flex-direction: column;
    }

    & .makaira-autosuggestion__manufacturer img {
      max-width: 120px;
      margin-right: 10px;
    }

    & .button {
      position: relative;
      background-color: var(--dark);
      color: white;
      margin-top: 28px;

      @media (width <= 480px) {
        width: 100%;
      }

      & > small {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--red);
        color: #fff;
        padding: 0.0625rem 0.3125rem;
        font-size: 0.6875rem;
        font-weight: 600;
        transform: translate(-30%,-50%);
      }
    }
  }
}

.makaira-search__results {
  padding: 0 30px;

  & .makaira-search__result-header {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2.7px;
    text-transform: uppercase;
    font-family: 'Roboto Slab', sans-serif;
    border: 0;
    height: 50px;
    display: flex;
    align-items: center;
  }

  & .makaira-search__result-item {
    & > a {
      --transition-time: .001s;
      color: var(--grey-text);
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: solid 1px #eee;
      text-decoration: none;
      text-overflow: ellipsis;
      text-transform: none;
      font-size: 16px;
      font-weight: 300;
      line-height: 50px;
      letter-spacing: 1px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

#makaira-empty {
  color: var(--red);
  display: none;

  &[style*=block] + div {
    display: none;
  }
}