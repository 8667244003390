.details {

  & #details_container {
    margin-bottom: 15px;
  }

  & .details__row {
    --row-max-width: 1280px;
  }

  & .details__sticky {
    position: sticky;
    top: 90px;
    z-index: 10;
  }

  & .details__breadcrumb {
    display: flex;
    justify-content: center;
    padding: 14px 0;
    font-size: 14px;

    @media (width > 780px) {
      background-color: #f9f9f9;
      margin-bottom: 50px;
      padding: 26px 0;
      font-size: 16px;
    }
  }


  & .details__pictures {
    overflow: hidden;

    & img[alt] {
      color: white;
    }

    & .preloadImages {
      background-color: white;
      overflow: hidden;

      & > img {
        display: block;
        margin: 0 auto;
      }
    }

    & .splideSlider {
      display: none;

      & > img {
        display: block;
        margin: 0 auto;
      }

      & .splide__slide a {
        display: block;

        & > img {
          display: block;
          margin: 0 auto;
        }
      }

    }

    & > .grid-row > .grid-column:nth-of-type(2) {
      @media (width > 780px) {
        order: -1;
        padding-right: 0;
      }
    }

    & .details__pictures--large {
      position: relative;

      & > img:not(:first-child) {
        display: none;
      }

      &:has(.splideSlider[style*=block]) .preloadImages {
        display: none;
      }

      & .splide__pagination {
        bottom: -28px;

        & .splide__pagination__page {
          opacity: 1;
          margin: 5px;

          &.is-active {
            background-color: var(--red);
            transform: none;
          }
        }
      }

      & .details__pictures--btns {
        color: var(--grey-text);
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        opacity: 1;
        transition: opacity .35s;
        pointer-events: none;
        flex-direction: column;
        box-shadow: 0 0 7px rgba(0, 0, 0, .2);
        border-radius: 5px;
        overflow: hidden;
        z-index: 10;


        & > div {
          color: var(--grey-text);
          min-width: 50px;
          height: 50px;
          padding: 0;
          display: inline-flex;
          flex-direction: row-reverse;
          align-items: center;
          flex-wrap: nowrap;
          overflow: hidden;
          pointer-events: auto;
          transition: all .3s ease-in-out;
          cursor: pointer;
          border-bottom: 1px solid var(--grey);

          &[data-favourite] {
            & svg:nth-last-of-type(1) {
              display: none;
            }
          }

          &.onlist {
            color: var(--red);

            & svg:nth-of-type(1) {
              display: none;
            }

            & svg:nth-last-of-type(1) {
              display: block;
            }
          }

          @media (hover: hover) {
            &:hover > span:nth-of-type(2) {
              max-width: 290px;
              padding-inline-start: 15px;
            }
          }

          & > span:nth-of-type(1) {
            background-color: white;
            width: 50px;
            height: 50px;
            line-height: 1;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }

          & > span:nth-of-type(2) {
            color: var(--grey-text);
            background-color: white;
            vertical-align: baseline;
            position: relative;
            z-index: 2;
            font-size: 14px;
            overflow: hidden;
            padding: 0;
            max-width: 0;
            height: 50px;
            white-space: nowrap;
            transition: padding 356ms cubic-bezier(.4, 0, .2, 1), max-width 356ms cubic-bezier(.4, 0, .2, 1);
            align-items: center;
            display: none;
          }
        }
      }
    }

    & .details__pictures--small {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 66px;

      @media (width > 780px) {
        margin: 0;
      }

      & .preloadImages {
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;

        @media (width > 780px) {
          flex-direction: column;
        }
      }

      & img {
        min-width: 47.5px !important;
        max-width: 47.5px !important;
      }

      &:has(.splideSlider[style*=block]) .preloadImages {
        display: none;
      }

      & ul > li {
        overflow: hidden;
        margin-bottom: 5px;
        padding: 1px;
        border: 1px solid transparent;
        margin-right: 5px;

        @media (width > 780px) {
          margin-right: 0;
        }

        &.active {
          border: 1px solid var(--red);
        }


        & > div {
          overflow: hidden;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      & [data-youtube-icon] {
        aspect-ratio: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;

        & > span {
          display: flex;
          position: absolute;
          top: 2px;
          right: 2px;
          bottom: 2px;
          left: 2px;
          z-index: 1;
          background-size: cover;
        }

        & > img {
          position: relative;
          z-index: 2;
          max-width: 40px;
        }
      }
    }
  }

  & .details__title {
    margin-top: 14px;
    font-size: 20px;
    font-weight: 500;
    font-family: Poppins, sans-serif;

    @media (width > 780px) {
      font-size: 26px;
      margin: 0;
    }

  }


  .details__stock {
    margin-top: 15px;

    & .details__stock--text {
      display: flex;
      align-items: center;
      font-weight: 500;
      text-transform: uppercase;

      & > img {
        margin-right: 6px;
      }

      & span {
        color: var(--red);
      }
    }

    & .details__stock--progressbar {
      width: 100%;
      display: block;
      position: relative;
      height: 12px;
      background-color: rgb(255, 232, 232);
      box-shadow: inset 0 1px 2px #0000001a;
      border-radius: 5px;
      margin: 10px auto 20px;

      & > div {
        background-color: rgb(247, 107, 106);
        background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
        background-size: 40px 40px;
        transition: width 1s;
        animation: 2s linear infinite progress_bar;
        height: 12px;
        border-radius: 5px;
      }
    }
  }

  & .details__hints + li:has(.price-wrapper) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  & .price-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & #productPriceMwst,
    & #productPriceUnit {
      font-size: 11px;
      display: flex;
      justify-content: flex-end;
      margin-top: 3px;
    }

    & #productPriceMwst {
      color: var(--grey-text);
      margin-left: 4px;
    }
  }

  & .details-additional-information {
    order: -1;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 3px;

    & del {
      order: 1;
      color: var(--grey-text);
      font-weight: 300;
      margin-inline-start: 10px;
      font-size: 14px;
    }

    & .red.sale {
      color: var(--red);
      font-size: 14px;
      font-weight: 500;
    }
  }

  & .details__price {
    & > div {
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
    }
  }

  & .details__accessories {

    & .alist__product {
      overflow: hidden;
    }

    & form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    & .relative {
      flex-grow: 1;
    }

    & h3 {
      margin-top: 0;
    }

    & select {
      width: 100%;
      padding: 7px 14px;
      height: auto;
      font-size: 14px;
    }

    & .alist__product--title {
      min-height: 52px;
    }

    & [type=submit] {
      width: 100%;
      justify-content: center;
      margin-top: 14px;
      &[disabled] {
        opacity: .4;
      }
    }
  }

  & .details__final--price {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    font-size: 30px;
    font-weight: 600;
    line-height: 25px;

    & .price-from {
      color: var(--grey-text);
    }

    & del {
      order: 1;
      color: var(--grey-text);
      font-weight: 300;
      margin-inline-start: 10px;
      font-size: 14px;
    }

    & span.red {
      color: var(--red);
    }

    & small {
      font-weight: normal;
      font-size: 11px;
      display: inline-block;
      margin-left: 4px;
    }
  }

  & .details__delivery {
    font-size: 11px;
    font-weight: 300;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    color: var(--grey-text);
    margin-top: 3px;
  }

  & .details__shortdesc {
    margin-top: 0;
    margin-bottom: 7px;
    color: var(--grey-text);
    font-size: 16px;
    font-weight: 300;

    & + div {
      margin-bottom: 18px;
    }
  }

  & .artist__link {
    width: max-content;
    text-decoration: none;
    display: flex;
    white-space: nowrap;
    background-color: var(--red);
    font-size: 12px;
    font-weight: 400;
    color: white;
    padding: 3px 6px;
    margin-right: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  /* selects & variants */

  & .details-info {
    --light-grey: #e9e9e9;
    margin-top: 25px;

    & .details-information {
      counter-reset: count;
    }

    & h4, & .dropDown > p {
      font-size: 16px;
      font-weight: 500;
      margin: 10px 0 0 0;
      font-family: "Roboto Slab", sans-serif;
    }

    & [data-dropdown-opener],
    & .dropdown-menu.vardrop [data-selection-id],
    & .persparamBox input {
      background-color: white;
      margin-top: 10px;
      padding: 3px 16px 2px 12px;
      line-height: 42px;
      font-size: 16px;
      color: var(--dark);
      border: 1px solid #ccc;
      border-radius: 3px;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: relative;
      text-align: inherit;
      font-weight: 300;

      & ~ [data-dropdown-list] {
        width: 100%;
        top: calc(100% + 5px);

        &::before {
          right: auto;
          left: 5%;
          display: none;
        }

        & a {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & .price.reduced {
            color: var(--red);

            & > del {
              font-size: 13px;
              color: var(--grey-text);
            }
          }

          & span[style*=background] {
            width: 25px;
            aspect-ratio: 1;
            position: absolute;

            & + span {
              margin-left: 35px;
            }
          }
        }
      }
    }

    & .dropDown[data-dropdown-color] {
      margin-top: 7px;
      margin-left: -7px;
      margin-right: -7px;

      & [data-dropdown-opener] {
        background-color: transparent;
        border: none;
        padding: 0;
        margin-left: 7px;
        line-height: 2;

        & svg {
          display: none;
        }
      }

      & [data-dropdown-list] {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: static;
        max-height: 10000px;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        overflow-y: visible;

        & li {
          background-color: transparent;
          border: 0;
          padding: 7px;

          & a {
            padding: 0;

            &.active {
              & span[style*=background] {
                border-radius: 50%;
              }
            }
          }

          & span[style*=background] {
            transition: all .3s;
            position: static !important;
            width: 35px !important;
            box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, .35);
            border: 1px solid #fff;
          }
        }
      }

      & .variant {
        display: none;
      }
    }

    & .selectorsBox {

      & > section {
        padding: 5px 16px 16px 16px;
        background-color: #FAFAFA;
        margin-bottom: 21px;
        box-shadow: 0 0 0 1px #0000001a;
        counter-increment: count;
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: counter(count);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          background-color: var(--dark);
          border-radius: 50%;
          color: white;
          font-family: Poppins, sans-serif;
          font-size: 11px;
          line-height: 14px;
          transform: translate(100%, -50%);
          aspect-ratio: 1;
        }
      }
    }

    & .persparamBox {
      & .pb-option-box {
        & + .product__accepted--letters {
          display: block;
          margin-top: 7px;
          font-size: 12px;
          letter-spacing: 2px;
        }

        & .js--error {
          display: none;
        }
      }
    }

    & .dropdown-menu.vardrop {
      display: flex;
      flex-wrap: wrap;
      margin: 7px -7px 0 -7px;


      & li {
        display: flex;
        min-width: 33.3333%;
        flex: 0 0 33.3333%;
        padding: 4px;

        @media (width > 480px) {
          flex: 0 0 25%;
        }

        &[data-multi-variant="2"] {
          @media (width > 480px) {
            min-width: min-content;
          }
        }


        &:hover a {
          border-color: var(--red);
        }

        &.active a {
          border-color: var(--red);
          border-width: 2px;

          & .selection__check {
            display: flex;
          }
        }

        & del.red {
          color: var(--red);
        }
      }

      & [data-selection-id] {
        --transition-time: .01s;
        flex: 1;
        margin-top: 0;
        text-decoration: none;
        position: relative;
        background-color: white;
        font-size: 16px;
        line-height: 1.4;
        font-family: Roboto Slab, sans-serif;
        padding: 7px;
        flex-direction: column;

        & > .selection__check {
          --transition-time: .1s;
          color: var(--red);
          position: absolute;
          top: 0;
          right: 0;
          display: none;
          transform: translate(25%, -25%);
          background-color: white;
          border-radius: 50%;
          border: 1px solid white;
        }

        & > div {
          display: flex;
          flex-direction: column;

          & > span {
            padding: 4px;
          }
        }

        & .variant {
          font-weight: 500;
          display: block;
          line-height: 1.4;
        }

        & .variant + .price {
          font-size: 14px;
          border-top: 1px solid var(--grey);

          & > small {
            white-space: nowrap;
          }
        }
      }
    }
  }

  & #ppExpressDetailsWrapper {
    & > br {
      display: none;
    }
  }

  /* basket button section styles */

  & .tobasket {
    display: flex;
    flex-direction: column;
  }

  & .tobasketFunction {
    margin-top: 15px;

    & > div {
      align-items: flex-end;
    }

    & .quantity-wrapper {
      position: relative;
      display: flex;
      height: 60px;
      background-color: rgba(238, 238, 238, .35);
      border: 1px solid rgba(238, 238, 238, .9);
      text-align: center;
      margin-inline-end: 15px;

      & > small {
        position: absolute;
        top: 0;
        left: 50%;
        font-weight: 500;
        font-size: 12px;
        transform: translate(-50%, -55%);
        display: block;
        background-color: white;
        padding: 2px 5px;
        line-height: 1;
        border-radius: 3px;
      }

      & > div {
        display: flex;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;

        &:hover {
          color: var(--red);
        }

        &:nth-of-type(1) {
          & svg {
            transform: rotate(90deg);
          }
        }

        &:nth-of-type(2) {
          & svg {
            transform: rotate(-90deg);
          }
        }
      }

      & input {
        flex-grow: 1;
        height: 58px;
        max-width: 40px;
        padding: 0;
        background: 0 0;
        border: 0;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        -moz-appearance: textfield;
      }

      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }
    }

    & .input-group-append {
      width: 100%;
    }

    & [type=submit] {
      width: 100%;
      min-height: 60px;
      background-color: var(--dark);
      border: 0;
      cursor: pointer;
      color: white;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 2px;
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: stretch;
      position: relative;
      overflow: hidden;

      & > div {
        --transition-time: .3s;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        width: 60px;
        height: 60px;
        position: absolute;
        left: -60px;
        top: -2px;
      }

      & > span {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &[disabled=disabled] {
        cursor: auto;
        opacity: .85;
        pointer-events: none;
      }

      &:not([disabled=disabled]) {
        &:hover > div,
        &.submitting > div {
          left: -10px;

          @media (width > 480px) {
            left: 0;
          }
        }
      }
    }
  }


  & .details__additionals {
    display: flex;
    justify-content: space-between;

    @media (width < 480px) {
      flex-direction: column;
    }

    &:has(.button) {
      margin-top: 31px;
    }

    & > div:nth-of-type(2) {
      @media (width >= 480px) {
        margin-left: 7px;
      }
    }

    & .button {
      min-height: 55px;
      position: relative;
      --fs: 13px;

      @media (width < 480px) {
        width: 100%;
        margin-bottom: 10px;
      }

      & > span {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        background-color: var(--dark);
        border-radius: 50%;
        color: white;
        font-family: Poppins, sans-serif;
        font-size: 11px;
        line-height: 14px;
        transform: translate(-100%, -50%);
        aspect-ratio: 1;
      }

      & > img {
        max-width: 30px;
        position: relative;
        left: -15px;
        display: none;
      }
    }
  }

  & .details__extra {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 18px;
    margin-top: 18px;
    border-bottom: 1px solid #e9e9e9;

    & svg {
      position: relative;
      top: 1px;
      margin-right: 7px;
    }

    & > * {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      color: var(--grey-text);
      text-decoration: none;
      font-size: 15px;
      font-weight: 300;

      &:hover {
        color: var(--red);
      }

      &:nth-of-type(1) {
        & svg {
          transform: rotateY(180deg);
        }
      }
    }
  }

  & .details__meta {
    & li {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      font-size: 16px;

      & * {
        font-weight: normal;
      }

      & > span {
        display: inline-flex;
        min-width: 120px;

        &:nth-of-type(1) {
          margin-right: 20px;
          font-weight: 400;
        }

        &:nth-of-type(2) {
          &.red {
            color: var(--red);
          }

          &.green {
            color: var(--green);
          }

          &, & * {
            color: var(--grey-text);
            font-weight: 300;
          }

          &.social div {
            display: inline-flex;
            margin-right: 20px;
          }

          & b.green {
            display: none;
          }
        }
      }

      & .stockFlag.onStock {
        color: var(--green);
      }
    }
  }


  & .details-related {
    padding: 10px 0;
    margin: 35px 0 30px 0;
    position: relative;

    @media (width > 780px) {
      margin: 60px 0;
      padding: 30px 0;
      background-color: var(--light-grey);
    }

    & .details-tabs {

      & .nav-tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        overflow: hidden;

        & a {
          text-decoration: none;
          font-weight: 400;
          color: var(--grey-text);
          display: inline-block;
          padding: 10px 0;
          margin: 0 15px;
          position: relative;
          font-size: 20px;

          &:hover, &.active {
            color: var(--dark);

            &::after {
              width: 100%;
            }
          }

          &::after {
            --transition-time: .15s;
            display: block;
            width: 0;
            content: "";
            position: absolute;
            height: 3px;
            background-color: var(--red);
            z-index: 1;
            left: 0;
            bottom: 0;
          }
        }
      }

      & .tab-content {
        padding: 20px 0;

        & > div {
          display: none;
          padding: 10px;

          @media (width > 780px) {
            padding: 0;
          }

          &.active.click {
            display: block;
          }

          &.active {
            @media (width > 780px) {
              display: block;
            }
          }
        }

        & > a[href^="#tab"] {
          text-decoration: none;
          color: var(--dark);
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: var(--grey);
          margin-bottom: 10px;
          padding: 14px;
          position: relative;

          @media (width > 780px) {
            display: none;
          }

          & > span {
            pointer-events: none;
            position: absolute;
            top: 0;
            right: 7px;
            bottom: 0;
            display: flex;
            z-index: 3;
            align-items: center;
            width: 40px;
            height: 40px;
            margin: auto;

            &::before, &::after {
              display: block;
              content: "";
              background-color: currentcolor;
              transition: .5s ease .1s;
            }

            &::before {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 2;
              width: 12px;
              height: 2px;
              margin: auto;
            }

            &::after {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 2;
              width: 2px;
              height: 12px;
              margin: auto;
            }
          }

          &.active > span {
            color: var(--red);

            &::after {
              opacity: 0;
              height: 0;
            }
          }
        }

        & :is(p,ul,b) {
          color: var(--grey-text);
        }

        & :is(b) {
          color: var(--dark);
        }

        & p > a {
          color: var(--red);
        }

        & h2, & h3, & h4 {
          font-family: "Roboto Slab", sans-serif;
          font-weight: 600;
        }

        & h2 {
          font-size: 20px;
          margin-top: 0;
        }

        & h3 {
          font-size: 18px;
        }

        & h4 {
          font-size: 16px;
        }

        & p {
          font-weight: 300;
          line-height: 27px;
        }

        & a, & b, & strong {
          font-weight: 500;
          color: var(--dark);
        }

        & *:not(.tab-content) a:not(.button) {
          color: var(--red);
        }


        & ul:not(.rating__list) {
          font-weight: 300;
          line-height: 34px;

          &:not(:has([data-image])) {
            padding-left: 20px;

            & li {
              list-style-type: disc;
            }

            & li::marker {
              color: var(--red);
            }
          }
        }

        & .details__tabs--images {
          display: flex;
          margin: 25px -10px 0 -10px;
          flex-wrap: wrap;

          & > * {
            padding: 10px;
            flex: 0 0 33.33333%;
            width: 33.33333%;
            max-width: 33.33333%;
          }
        }

        & .details__tabs--videos {
          display: flex;
          margin: 0 -10px;
          flex-wrap: wrap;

          & > * {
            aspect-ratio: 16/9;
            padding: 10px;
            flex: 0 0 33.33333%;
            width: 33.33333%;
            max-width: 33.33333%;
          }
        }
      }
    }

    & .brandLogo, & .artist__link {
      position: absolute;
      left: 50%;
      top: 0;
      z-index: 5;
      transform: translate(-50%, -50%);
    }

    & .brandLogo {
      max-width: 200px;

      & a {
        display: block;
      }

      & + .details-tabs {
        margin-top: 35px;
      }
    }
  }

  & .oxinvalid {
    padding-left: 40px;
    position: relative;

    & input, & p {
      border-color: var(--red) !important;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: "!";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      aspect-ratio: 1;
      color: white;
      border-radius: 3px;
      background-color: var(--red);
      font-weight: 600;
    }
  }

  & .details__hints {
    display: flex;
    flex-direction: column;
    position: relative;

    & .details__exclusiv {
      font-size: 12px;
      margin-top: 7px;
    }

    & .info-btn-area {
      position: static;

      & > div {
        &::before, &::after {
          display: none;
        }
      }
    }
  }


  & .details__artnum {
    font-size: 11px;
  }

  & .details__shipping {
    font-size: 11px;

    &:before {
      display: inline-block;
      content: "";
      width: 8px;
      height: 8px;
      background-color: #6a8e06;
      border-radius: 100%;
      margin-right: 2px;
      opacity: .75;
    }
  }


  & #svgPreview {
    position: absolute;
    width: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: white;
    pointer-events: none;

    &:has(img[src*=show]) {
      z-index: 10;
      opacity: 1;
      width: 100%;
      pointer-events: auto;
    }

    & > div {
      border: 0;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      transition: transform .15s ease-out;
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--dark);
      color: white;
      cursor: pointer;
    }
  }

  & .details__quickview {

    & .details__quickview--img {
      aspect-ratio: 1/1.2;
      position: relative;

      & img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        max-width: none;
      }
    }

    & .details__quickview--thumbs {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      width: 101%;

      & > div {
        width: 19%;
        margin-right: 1%;
        aspect-ratio: 1;

        & img {
          padding: 1px;
          border: 1px solid transparent;

          &.active {
            border: 1px solid var(--red);
          }
        }
      }
    }

    & .details-additional-information {
      & del {
        margin-left: 0;
      }
    }

    & .price-wrapper {
      align-items: flex-start;
      margin-top: 15px;

      & .details__price {
        & > div {
          justify-content: flex-start;

          &:not(:has(#productPriceUnit)) #productPriceMwst {
            margin-left: 0;
          }
        }
      }
    }
  }
}


@keyframes progress_bar {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
