.alist {

  & .header__main > .grid-row {
    @media (width > 1280px) {
      --row-max-width: 96%;
    }
  }

  & .alist__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px 14px 0 14px;
    margin-bottom: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120% auto;
    font-family: Poppins, sans-serif;

    @media (width > 480px) {
      padding: 30px 14px 0 14px;
      margin-bottom: 35px;
    }

    & > small {
      color: var(--grey-text);
      white-space: nowrap;
    }

    & h1 {
      margin: 0;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      font-family: Poppins, sans-serif;

      @media (width > 480px) {
        font-size: 32px;
      }
    }

    &[style*="background-image"] {
      padding-top: 60px;
      padding-bottom: 60px;
      border-bottom: 1px solid var(--grey);

      & h1, & .breadcrumb * {
        color: white;
      }
    }
  }

  & .alist__topbar {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid var(--grey);
    color: #888;
    font-family: Poppins, sans-serif;
    font-size: 14px;

    @media (width > 780px) {
      margin-bottom: 24px;
    }

    & > div {
      --transition-time: .1s;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px;
      font-weight: 300;
      flex-grow: 1;

      @media (width > 780px) {
        padding: 19px;
        flex-grow: initial;
      }

      &[class]:not(.alist__topbar--count) {
        cursor: pointer;

        &:hover, &.is-active {
          color: var(--red);
        }
      }

      &:not(:nth-last-of-type(1)) {
        border-right: 1px solid var(--grey);
      }

      & svg {
        --transition-time: .001s;
      }

      &.alist__topbar--filter,
      &.alist__topbar--cats {
        & svg {
          margin-inline-end: 7px;
          position: relative;
          top: -1px;
        }
      }

      &.alist__topbar--sort {
        @media (width <= 780px) {
          border-right: 0;
        }

        & svg {
          margin-inline-start: 21px;
        }
      }

      &.alist__topbar--pagination {
        & .tooltip {
          width: 18px;
          height: 18px;
        }

        & .tooltip:nth-of-type(1) svg {
          transform: rotate(90deg);
        }

        & .tooltip:nth-last-of-type(1) svg {
          transform: rotate(-90deg);
        }

        & .page {
          margin: 0 7px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: white;
          min-width: 18px;
          height: 18px;
          background-color: #888;
        }

        & #nopage {
          opacity: .75;
          color: #888 !important;
          cursor: auto;
        }
      }
    }

    & > div:empty {
      flex-grow: 1;
    }
  }

  & .alist__filter-active {
    position: relative;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 1px;
    margin-bottom: 15px;
    border: solid 1px var(--grey);
    color: var(--grey-text);
    font-size: 16px;
    line-height: 20px;
    padding-inline-start: 15px;
    padding-inline-end: 80px;
    font-weight: 300;

    @media (width > 1280px) {
      margin-bottom: 25px;
    }

    & > div {
      &:nth-of-type(2) {
        font-size: 14px;
      }

      &:nth-of-type(2) {
        font-size: 14px;
      }
    }

    & span {
      & > a {
        color: var(--dark);
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px;

        &:hover {
          color: red;
          text-decoration: underline;
        }

        &::after {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          content: "X";
          width: 18px;
          height: 18px;
          background-color: var(--red);
          margin: 0 5px 0 3px;
          color: white;
          font-size: 13px;
          font-weight: 600;
          border-radius: 3px;
        }

        &:not(:first-child)::before {
          display: none;
          content: ", ";
        }

        &:not(:first-child) {
          position: relative;
          left: -2px;
        }
      }
    }
  }

  & .alist__row {
    --row-max-width: 98%;

    @media (width > 780px) {
      --row-max-width: 97%;
    }
  }

  & .alist__sidebar {
    padding-top: 20px;
    background-color: var(--light-grey);
    max-width: 96%;

    /* overwrite navigation styles */

    & .navigation {
      & .navigation__sidebar {
        padding: 0 30px;

        & .navigation__sidebar--item.home .navigation__sidebar--link {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 2.7px;
          text-transform: uppercase;
          font-family: 'Roboto Slab', sans-serif;
          border: 0;
          margin: 0;

          & > div {
            padding-left: 0;
          }
        }

        & .navigation__sidebar--item:not(.home) .navigation__sidebar--link {
          text-transform: none;
          color: var(--grey-text);

          &:hover {
            color: var(--red);
          }

          & > div {
            padding-left: 0;
          }
        }
      }
    }

  }

  & .alist__products {
    & > ul {
      min-width: 100%;
    }

    & .alist__product {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;

      & > a {
        display: block;
        width: 100%;
      }

      /*  product hover */

      &:hover {
        &:hover .alist__product--picture img {
          transform: scale(1.05);
        }

        &:hover .alist__product--picture:has(> :last-child:nth-child(2)) img:nth-of-type(1) {
          opacity: 0;
        }

        & .alist__product--btns {
          & > div {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
            animation: slideDivUp .3s ease both;

            &:nth-of-type(2) {
              animation-delay: .2s
            }

            &:nth-of-type(3) {
              animation-delay: .4s
            }

            &:nth-of-type(4) {
              animation-delay: .6s
            }

            &[data-favourite] {
              & svg:nth-last-of-type(1) {
                display: none;
              }
            }

            &.onlist {
              color: var(--red);

              & svg:nth-of-type(1) {
                display: none;
              }

              & svg:nth-last-of-type(1) {
                display: block;
              }
            }

          }
        }
      }

      & a {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        color: inherit;
        text-align: center;
        text-decoration: none;
        overflow: hidden;
        opacity: 0;
        transform: translateY(0);
        transition-delay: 0s;

        &.loaded {
          opacity: 1;
        }
      }

      & .alist__product--picture {
        overflow: hidden;
        position: relative;
        aspect-ratio: 1;

        & img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-position: center center;
          object-fit: scale-down;
          max-width: none;
          background-color: white;
        }

        img:nth-of-type(1) {
          z-index: 2;
        }

        img:nth-of-type(2) {
          z-index: 1;
        }

      }

      & .alist__product--btns {
        display: none;
        border: solid 1px var(--grey);

        & svg {
          --transition-time: .001s;
        }

        @media (width > 1024px) and (hover: hover) {
          display: flex;
          position: absolute;
          z-index: 4;
          bottom: 16px;
          left: 50%;
          transform: translateX(-50%);
          max-width: max-content;
          border-color: transparent;
          will-change: auto;
        }

        & > div {
          height: 45px;
          display: flex;
          flex-grow: 1;
          justify-content: space-around;
          align-items: center;
          background-color: white;


          @media (width > 1024px) {
            width: 45px;
            transform: translateY(30px);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            border: 1px solid #eee;
          }

          & svg:nth-of-type(2) {
            display: none;
          }

          &:not(:last-of-type) {
            @media (width > 1024px) {
              border-right-color: transparent;
            }
          }

          &:hover {
            color: var(--red);
          }

          &:nth-of-type(1) {
            border-radius: 5px 0 0 5px;
          }

          &:nth-of-type(2) {
            border-radius: 0 5px 5px 0;
          }
        }
      }

      & .alist__product--info {
        flex-grow: 1;
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (width > 780px) {
          padding-top: 20px;
        }
      }

      & .alist__product--title {
        display: -webkit-box;
        margin: 0 0 7px 0;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 400;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;

        @media (width > 780px) {
          font-size: 17px;
        }
      }

      & .alist__product--price {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      & .alist__product--fprice,
      & .alist__product--delprice,
      & .alist__product--uprice {
        color: #7b7b7b;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 400;

        @media (width > 780px) {
          font-size: 16px;
        }
      }

      & .alist__product--delprice + .alist__product--fprice {
        color: var(--red);
        margin-left: 3.5px;
        white-space: nowrap;
      }

      & .alist__product--delprice {
        margin-right: 3.5px;
        white-space: nowrap;
      }

      & .alist__product--uprice {
        margin-left: 7px;
        white-space: nowrap;
      }
    }
  }

  & .alist__recos {
    --border-width: 1px;
    max-width: 100%;
    margin-bottom: 28px;
    border-bottom: var(--border-width) solid var(--grey);

    @media (width > 780px) {
      margin-bottom: 40px;
    }

    & .headline {
      margin: 0 auto;
    }

  }

  & .alist__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 60px 0;

    & > div {
      width: 100%;
      padding: 0 10px;
    }

    & .pagination {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }

    & .page-item {
      & > * {
        min-width: 50px;
        height: 50px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: solid 1px var(--grey);
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-weight: 500;
      }

      & .previous svg {
        transform: rotate(180deg);
      }

      &.active a {
        border-color: var(--dark);
        background-color: var(--dark);
        color: white;
      }

      & a {
        --transition-time: .1s;
        display: flex;
        text-decoration: none;
        color: inherit;

        &:hover {
          border-color: var(--red);
          background-color: var(--red);
          color: white;
        }
      }

      & svg {
        --transition-time: .001s;
        width: 18px;
      }
    }
  }

  & .alist__description {
    padding-top: 60px;
    padding-bottom: 30px;
    background-color: var(--light-grey);
    font-family: Poppins, sans-serif;

    & * {
      text-align: center;
    }

    & h2 {
      font-weight: 500;
      font-size: 28px;
    }

    & h3, & h4, & h5, & h6 {
      font-weight: 500;
    }

    & p {
      --fsf: 14px;
      font-weight: 400;
      color: #878787;
      line-height: 30px;
      font-size: var(--fsf);

      @media (width > 780px) {
        --fsf: 18px;
      }
    }

    & a {
      color: var(--dark);
    }
  }

  /* alist empty styles */

  & .alist__empty {

    & h2 {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 2.7px;
      text-transform: uppercase;
      font-family: 'Roboto Slab', sans-serif;
    }

    & .alist__empty--text {
      margin-bottom: 28px;

      & br, & hr {
        display: none;
      }

      & p {
        &:nth-of-type(1) {
          margin-top: 0;
        }

        color: var(--grey-text);
      }
    }

    & .w100 {
      position: relative;

      & h2 {
        position: relative;
        z-index: 2;
        display: inline-block;
        background-color: white;
        padding-right: 7px;
      }

      &::before {
        position: absolute;
        top: 49%;
        display: block;
        content: "";
        width: 100%;
        height: 1px;
        background-color: var(--grey);
      }
    }

    & .alist__empty--item {
      margin-bottom: calc(var(--block-grid-space) * 2);

      & a {
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 180px;
        height: 100%;
        text-decoration: none;
        color: inherit;

        & > img {
          display: block;
        }

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          margin-top: 20px;

          & span:nth-of-type(1) {
            font-size: 18px;
            font-family: "Roboto Slab", sans-serif;
            font-weight: 500;
          }

          & span:nth-of-type(2) {
            color: var(--grey-text);
            display: block;
            margin-top: 7px;
          }
        }
      }
    }
  }
}

/*animations*/
@keyframes slideDivUp {
  0% {
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }
}


