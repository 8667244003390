.account {

  form[name="login"] {
    & .form-group > ul {
      & > li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      & a {
        text-decoration: none;
        color: var(--grey-text);
        &:hover {
          color: var(--dark);
          text-decoration: underline;
        }
      }
    }
  }

  & a#openAccountLink {
   color: var(--red);
  }

  & .card {
    border: 1px solid #d4d4d4;
    background-color: rgba(238, 238, 238, 0.35);
    padding: 20px;
    margin-bottom: 10px;
    font-weight: 300;

    & a {
      color: inherit;
      font-weight: 400;
    }
  }

}

