.notices {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 69900;
  color: white;
  cursor: pointer;
  transform: translate3d(0, -100%, 0);
  transition: all .35s ease 0s;
  min-height: 60px;
  padding: 10px 80px 10px 65px;
  border: 0;

  &.exclamation {
    background-color: var(--yellow);
    & .notices__msg svg:nth-of-type(2) {
      display: none;
    }
  }

  &.success {
    background-color: #3EB56E;
    & .notices__msg svg:nth-of-type(1) {
      display: none;
    }
  }

  &.is-open {
    transform: none;

    & > .flex > svg {
      transform: scale(1);
    }
  }

  & > .flex {
    align-items: center;
    width: 100%;

    & > svg {
      transform: scale(0);
      width: 45px;
      margin-right: 10px;
    }
  }

  & .notices__message {
    font-weight: 300;
  }

  & .notices__close {
    position: absolute;
    inset: 0 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    background-color: #0000001a;
    font-weight: 500;
    font-size: 45px;
    cursor: pointer;
    transition: background-color .25s ease 0s;
  }
}