.discount {
  text-align: center;

  &.discount__header {
    position: relative;
    z-index: 110;
    padding: 5px 3px;
    background-color: var(--red);
    color: white;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    line-height: 1.2;
    border-bottom: 1px solid white;

    @media (width > 480px) {
      font-size: 14px;
    }

    &.discount__product {
      z-index: 1;
      margin-top: 10px;
      &:hover .discount__code b {
        text-decoration: underline;
      }
    }

    & .discount__text {
      text-transform: uppercase;
    }

    &, & * {
      text-rendering: geometricPrecision;
    }

    & *:not(i) {
      display: inline-block;
    }

    & .discount__down {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 0;
      display: flex;
      z-index: 3;
      align-items: center;
      width: 27px;
      height: 27px;
      background: url("/out/wallart/img/svg/arrow-bottom-white.svg") no-repeat center center;
      background-size: 36%;
    }


    & .discount__break {
      display: none;

      @media (width <= 480px) {
        display: block;
        width: 0;
        height: 0;
        opacity: 0;
      }
    }

    & > div:not(:nth-last-of-type(1))::after {
      position: relative;
      content: "|";
      font-size: 15px;
      user-select: none;
      display: inline-block;
      margin: 0 7px;
      opacity: .6;
      font-weight: 300;
    }

    & .discount__exceptions {
      background-color: var(--red);
      position: absolute;
      top: 95%;
      right: 0;
      left: 0;
      z-index: 7;
      display: none;

      & a {
        color: inherit;
        text-decoration: underline;
      }

      & p {
        font-weight: 400;
        font-size: 12px;
        margin: 7px;
      }
    }
  }
}
