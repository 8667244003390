.footer {

  & .footer__page {
    background-color: #000;
    color: var(--grey-text);

    & .footer__row {
      --row-max-width: 1640px;
    }

    & a {
      color: var(--grey-text);
      text-decoration: none;
    }


    & .footer__impress {
      --fsf: 14px;
      --pdf: 21px;
      padding: var(--pdf) 0;
      background-color: #191919;
      font-size: var(--fsf);

      @media (width > 780px) {
        --pdf: 37px;
        --fsf: 16px;
      }
    }

    & .footer__middle {
      --fsf: 14px;
      --pdf: 21px;
      padding-top: var(--pdf);
      padding-bottom: calc(var(--pdf) / 1);
      font-size: var(--fsf);

      @media (width > 780px) {
        --pdf: 100px;
        --fsf: 16px;
      }
    }

    & .footer__bottom {
      --pdf: 21px;
      --fsf: 14px;
      padding: var(--pdf) 0;
      border-top: 1px solid var(--grey-text);
      text-align: center;
      font-size: var(--fsf);

      @media (width > 780px) {
        text-align: left;
        --pdf: 55px;
        --fsf: 16px;
      }

      @media (width <= 1280px) {
        padding-bottom: calc(var(--pdf)*4);
      }

      & > .grid-column {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    & .footer__menus {

      & h3 {
        --fsf: 16px;
        margin-top: 0;
        padding-bottom: calc(var(--fsf) / 2);
        margin-bottom:  calc(var(--fsf) / 2);
        font-weight: 500;
        font-size: var(--fsf);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--grey-text);
        font-family: Poppins, sans-serif;

        @media (width > 780px) {
          --fsf: 18px;
          border-width: 0;
        }

        & svg {
          opacity: .8;

          @media (width > 780px) {
            display: none;
          }
        }
      }

      & a {
        --transition-time: .1s;
        display: flex;
        max-width: max-content;
        margin-bottom: 10px;

        &:hover {
          color: white;
        }
      }

      & > ul > li > ul {
        display: none;

        @media (width > 780px) {
          display: block !important;
        }

        & li {
          margin-left: 20px;
          list-style-type: disc;
        }

        & li::marker {
          color: inherit;
        }
      }
    }

    & .footer__copy {
      margin-bottom: 15px;
    }

    & .footer__payment {
      margin-bottom: 28px;

      & .payment-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        & a {
          position: relative;
          width: 80px;
          margin: 0 9px 9px 0;
          background-color: white;
          aspect-ratio: 16/9;
        }

        & .sprite {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% auto;
        }

        & [class*=paypal] {
          background-image: url(/out/wallart/img/payment/paypal.svg);
          background-size: 90% auto;
        }
        & [class*=amazonePay] {
          background-image: url(/out/wallart/img/payment/amazon.svg);
          background-size: 80% auto;
        }
        & [class*=visa] {
          background-image: url(/out/wallart/img/payment/visa.svg);
          background-size: 80% auto;
        }
        & [class*=mastercard] {
          background-image: url(/out/wallart/img/payment/mastercard.svg);
          background-size: 65% auto;
        }
        & [class*=americanexpress] {
          background-image: url(/out/wallart/img/payment/amex.svg);
          background-size: 75% auto;
        }
        & [class*=klarna] {
          background-size: 80% auto;
          background-image: url(/out/wallart/img/payment/klarna.svg);
        }
        & [class*=klarna-1] {
          background-image: url(/out/wallart/img/payment/klarna-installment.svg);
        }
        & [class*=klarna-2] {
          background-image: url(/out/wallart/img/payment/klarna-invoice.svg);
        }
        & [class*=vorkasse],
        & [class*=bonifico],
        & [class*=overschrijving] {
          background-image: url(/out/wallart/img/payment/sepa.svg);
        }
        & [class*=maestro] {
          background-image: url(/out/wallart/img/payment/maestro.svg);
          background-size: 65% auto;
        }
        & [class*=ideal] {
          background-image: url(/out/wallart/img/payment/ideal-dark.jpg);
          background-size: 60% auto;
        }
        & [class*=cartebleue] {
          background-image: url(/out/wallart/img/payment/cartebleue.svg);
          background-size: 70% auto;
        }
        & [class*=poste-pay] {
          background-image: url(/out/wallart/img/payment/postepay.svg);
          background-size: 54% auto;
        }
        & [class*=dankort] {
          background-image: url(/out/wallart/img/payment/dankort.svg);
          background-size: 90% auto;
        }
        & [class*=giropay] {
          background-image: url(/out/wallart/img/payment/giropay.svg);
          background-size: 90% auto;
        }
        & [class*=bancontact] {
           background-image: url(/out/wallart/img/payment/bancontact.svg);
          background-size: 90% auto;
        }
        & [class*=cartes-bancaire] {
          background-image: url(/out/wallart/img/payment/cartes-bancaire.svg);
          background-size: 50% auto;
        }
        & [class*=in3] {
          background-image: url(/out/wallart/img/payment/in3.svg);
          background-size: 50% auto;
        }
        & [class*=applepay] {
          background-image: url(/out/wallart/img/payment/applepay.svg);
          background-size: 85% auto;
        }
        & [class*=eps-online] {
          background-image: url(/out/wallart/img/payment/eps-online.svg);
          background-size: 85% auto;
        }
        & [class*=billie] {
          background-image: url(/out/wallart/img/payment/sprite-billie.svg);
          background-size: 85% auto;
        }
        & [class*=mybank] {
          background-image: url(/out/wallart/img/payment/mybank.svg);
          background-size: 85% auto;
        }
        & [class*=kbc] {
          background-image: url(/out/wallart/img/payment/kbc.svg);
          background-size: 50% auto;
        }
        & [class*=belfius] {
          background-image: url(/out/wallart/img/payment/belfius.svg);
          background-size: 50% auto;
        }
      }
    }

    & .footer__copy {
      font-weight: 300;
    }

    & .b2b__menu {
      display: flex;
      flex-direction: column;
      max-width: 90%;

      @media (--small) {
        order: -1;
      }

      & .b2b__menu--header {
        --fsf: 16px;
        display: block;
        margin-bottom: 14px;
        font-weight: 500;
        font-size: var(--fsf);

        @media (width > 780px) {
          --fsf: 18px;
        }
      }

      & small {
        line-height: 1.6;
      }

      & .b2b__link {
        display: flex;
        align-items: center;
        width: 100%;
        color: white;
        font-size: 16px;
        font-weight: 500;

        &::before {
          display: inline-block;
          width: 50px;
          margin-right: 7px;
          background: url("/out/wallart/img/b2b/b2b_logo.svg") no-repeat;
          background-size: auto 100%;
          content: "";
          aspect-ratio: 1;
        }

        & span {
          position: relative;
          top: -5px;
          display: inline-block;
          padding-bottom: 6px;
          border-bottom: 2px solid #f5a557;
        }

      }
    }

    & .social {
      & h3 {
        display: none;
      }

      & > div {
        display: flex;
        justify-content: flex-end;

        & > div {
          margin-left: 10px;
        }
      }
    }
  }

  & .footer__toolbar {
    position: fixed;
    right: 15px;
    bottom: 15px;
    left: 15px;
    display: none;
    z-index: 210;
    height: 55px;
    box-shadow: 0 0 7px rgba(0, 0, 0, .3);
    background-color: white;
    border-radius: 8px;

    & .navigation__icons {
      & a, & > div {
        max-width: 50px;
        @media (width > 780px) {
          max-width: 52px;
        }
        &[onclick*=menuFlyout] {
          position: relative;
          top: 3px;
        }
      }
    }

    & > div {
      display: flex;
      flex: 1 0 20%;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 3px 0 0 0 !important;

      &:not(:nth-last-of-type(1)) {
        border-right: 1px solid var(--grey);
      }
    }
  }

  & .footer__social {
    & h3 {
      margin-top: 0;
    }
    & a {
      display: inline-flex;
      margin-right: 7px;
    }
  }
}