.glossar {
  & .glossar__letters {
    display: flex;
    flex-wrap: wrap;
    border: 3px solid #d4d4d4;
    width: fit-content;
    padding: 1px;
    margin: 10px auto;


    @media (width > 780px) {
      margin: 60px auto;
    }

    & .glossar__letter:not(:has(a)),
    & a {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;
      margin: 0;
      padding: 14px 15.6px;
      display: block;
      text-decoration: none;
      color: var(--dark);
    }

    & a:hover {
      color: white;
      background-color: var(--red);
    }

    & .glossar__letter:not(:has(a)) {
      opacity: .1;
      pointer-events: none;
    }
  }


  & .glossar__categorys {
    & a  {
      color: var(--grey-text);
      display: block;
      margin-bottom: 10px;
      text-decoration: none;
      font-weight: 300;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}