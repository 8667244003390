.colorsample {

  & p {
    color: var(--grey-text);
  }

  & .wacolorwidget {
    & .colorbox {
      margin-bottom: 21px;

      & .color-id--1 {
        display: none;
      }

      & .option {
        cursor: pointer;
        display: block;
        aspect-ratio: 16/9;
        border: 1px solid var(--grey);
        &.active {
          opacity: .2;
        }
      }

      & p {
        color: var(--grey-text);
        font-size: 12px;
      }
    }
  }


  & .colorsample__choosed {
    margin-bottom: 10px;

    &[style*=block] {
      display: flex !important;
      align-items: center;
    }

    & b {
      font-weight: 600;
      font-family: "Roboto Slab", sans-serif;
    }

    & > div {
      display: inline-block;
      margin-left: 6px;
      padding: 8px 0 0 0;

      & > div {
        display: inline-block;
        margin-right: 14px;
        width: 40px;
        height: 20px;
        border: 1px solid var(--color-border-light);
      }
    }
  }

  & form {
   padding-left: 14px;
    & .formular__headline,
    & [name="invadr[oxuser__oxcompany]"],
    & [name="invadr[oxuser__oxustid]"],
    & select {
      width: 95%;
    }
  }
}