.attributes {
  display: flex;
  flex-wrap: wrap;
  
  & dt {
    font-family: "Roboto Slab", sans-serif;
    color: var(--dark) !important;
    font-weight: 500;
    min-width: 20%;
  }

  & dd {
    min-width: 80%;
  }

  & dt, & dd {
    margin: 0;
    padding: 10px;
    border: 1px solid var(--grey);

    @media (width <= 780px) {
      min-width: 100%;
      flex-grow: 1;
    }
  }

}