.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &:not(.checkout) {
    & * {
      font-family: Poppins, sans-serif;
      font-weight: 300;
    }

    & li {
      display: flex;

      &:is(.passed,.active) > a {
        color: var(--red);
      }
    }

    & a, & li > div {
      --transition-time: .001s;
      text-decoration: none;
      color: #888;

      &:hover span {
        text-decoration: underline;
        color: var(--red);
      }

      & small {
        margin: 0 5px;
        color: #888;
        opacity: .5;
        text-decoration: none;
      }
    }

    & > li:nth-last-of-type(1) > a > small {
      display: none;
    }
  }

  &.checkout {
    --brColor: var(--grey-text);
    position: relative;
    flex-wrap: nowrap;
    top: 20px;

    @media (width > 780px) {
      margin-left: 30px;
      top: -5px;
    }

    & li {
      border-top: 2px solid var(--grey);
      position: relative;

      &:is(.passed,.active) {
        --brColor: var(--green-dark);
        & > svg:nth-of-type(2) {
          display: none;
        }
      }

      & > svg {
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%,-60%);
      }

      &:nth-of-type(1) {
        & > svg {
          transform: translate(-100%,-60%);
        }
        & div {
          padding-left: 0;
        }
      }

      &:nth-last-of-type(1) {
        & > svg {
          transform: translate(20%,-60%);
        }
        & div {
          padding-right: 0;
        }
      }

      & * {
        color: var(--brColor);
        font-size: 12px;
      }

      & div {
        display: flex;
        align-items: center;
        position: relative;
        padding: 12px 14px;

        @media (width > 780px) {
          padding: 12px 26px;
        }
      }
      & a {
        text-decoration: none;
        color: inherit;
      }
      & span {
        display: block;
        font-weight: 500;
      }

      & > div > svg {
        margin-right: 3px;
      }
    }
  }
}