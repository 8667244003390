.bulletpoints {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  position: relative;

  @media (width > 780px) {
    margin-top: 14px;
  }

  & > ul {
    font-family: "Roboto Slab", sans-serif;

    & li {
      list-style-type: disc;
      margin: 1px 20px;
      line-height: 1.8;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 300;

      &::marker {
        color: var(--red);
      }
    }
  }

  & .bulletpoints--images {
    display: flex;
    flex-direction: column-reverse;
  }
}