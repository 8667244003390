.navigation {


  & .navigation__icons {
    position: relative;
    right: -7px;
    top: 1px;

    @media (width > 780px) {
      top: 4px;
      right: 0;
    }


    & li > a, & li > div:not([hidden]) {
      position: relative;
      display: block;
      padding: 0 10px;
      color: inherit;
      text-decoration: none;
      @media (width > 480px) {
       padding: 0 12px;
      }
    }


    & .navigation__circle {
      position: absolute;
      top: 50%;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      background-color: var(--red);
      border-radius: 50%;
      color: white;
      font-family: Poppins, sans-serif;
      font-size: 11px;
      line-height: 14px;
      transform: translate(-50%, -50%);
      aspect-ratio: 1;

      &.useraccount {
        transform: translate(-50%, -30%);
        background-color: var(--green);
      }
    }
  }

  & .navigation__burger {
    margin-right: 14px;
    margin-left: 4px;

    @media (width > 1024px) {
      display: none;
    }

    & div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 32px;
      height: 24px;

      & span {
        display: block;
        width: 100%;
        height: 4px;
        background-color: var(--dark);

        &:nth-of-type(2) {
          width: calc(100%);
        }

        &:nth-of-type(3) {
          width: calc(100%);
        }
      }
    }
  }


  & .navigation__main {
    display: none;

    @media (width > 1024px) {
      display: flex;
    }

    & .navigation__main--item {
      &:hover {
        & > .navigation__main--link {
          color: var(--red);

          &:after {
            transform: scaleZ(1);
            transform-origin: 0 50%;
          }
        }

        & > .navigation___main--flyout {
          @media (hover: hover) {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transform: translateY(0);
          }
        }
      }
    }

    & .navigation__main--link {
      --transition-time: .2s;
      position: relative;
      display: block;
      padding: 28px 0 32px 0;
      margin: 0 7px;
      color: inherit;
      text-decoration: none;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: .5px;
      white-space: nowrap;

      @media (width > 1280px) {
        margin: 0 11px;
      }

      &.is-active {
        color: var(--red);

        &:after {
          transform: scaleZ(1);
          transform-origin: 0 50%;
        }
      }

      &::after {
        --transition: transform;
        --transition-time: .3s;
        --transition-prop: cubic-bezier(.8, 0, .2, 1);
        transition-delay: .25s;
        position: absolute;
        right: 0;
        bottom: 25%;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
        background-color: var(--red);
        transform: scale3d(0, 1, 1);
        transform-origin: 100% 50%;
        content: "";
      }

      &[data-id="56284786a9bb2e9334d06c53b8f303a8"]::before {
        position: absolute;
        top: -50px;
        right: -10px;
        display: none;
        width: max-content;
        padding: 0 5px;
        background-color: var(--red);
        color: white;
        text-transform: capitalize;
        font-size: 10px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0;
        animation: .25s linear 2s forwards delayedShow;
        content: "HOT";
      }
    }

    & .navigation___main--flyout {
      --transition-time: .3s;
      transform: translateY(10px);
      position: fixed;
      left: 0;
      right: 0;
      background-color: white;
      max-width: 100vw;
      padding: 22px 15px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      box-shadow: 0 0 3px #00000026;
      transition-delay: .25s;


      & > a {
        margin: 10px auto;
      }


      & .navigation__submenu {
        display: flex;
        max-width: 70vw;
        margin: 0 auto;

        & div[class*=navigation__submenu] {
          display: flex;

          & > div {
            display: flex;
            flex-direction: column;
          }
        }

        & a {
          --transition-time: .1s;
          text-decoration: none;
          font-size: 14px;
          line-height: 24px;
          padding: 4px 10px;
          color: var(--grey-text);

          &:hover {
            color: var(--red) !important;
          }
        }

        & .navigation__submenu--leftColumn {
          min-width: 160px;

          & a {
            font-weight: 500;
            color: var(--dark);
          }
        }

        & .navigation__submenu--middleColumn {
          flex-grow: 1;
          flex-wrap: wrap;

          & > div {
            width: 25%;
            max-width: 25%;
            flex: 0 0 25%;
            margin-bottom: 21px;

            & > a:first-child {
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 2px;
              color: var(--dark);
              margin: 0;
            }
          }

          &.count__1 > div {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
          }

          &.count__2 > div {
            width: 50%;
            max-width: 50%;
            flex: 0 0 50%;
          }

          &.count__3 > div {
            width: 33.333%;
            max-width: 33.333%;
            flex: 0 0 33.333%;
          }
        }

        & .navigation__submenu--rightColumn {
          & h2 {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 2px;
            color: var(--dark);
            margin: 0;
            line-height: 30px;
            text-transform: uppercase;
          }

          &.count__1 {
            min-width: 360px;
          }

          &.count__2 {
            max-width: 600px;
          }
        }
      }

      &[id="27cd95"] {
        & .navigation__submenu--middleColumn.count__3 {
          min-width: 50%;
        }
      }

      &[id="b84152"] {
        & .navigation__submenu--middleColumn.count__1 {
          min-width: 200px;

          & > div {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
      }
    }
  }

  & .navigation__sidebar {

    & .navigation__sidebar--link {
      --transition-time: .001s;
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: solid 1px #eee;
      color: var(--dark);
      text-decoration: none;
      text-overflow: ellipsis;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 1px;
      white-space: nowrap;
      overflow: hidden;

      & > div {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-inline-start: 15px;

        & span:nth-of-type(2) {
          pointer-events: none;
          position: absolute;
          top: 0;
          right: 7px;
          bottom: 0;
          display: flex;
          z-index: 3;
          align-items: center;
          width: 40px;
          height: 40px;
          margin: auto;

          &::before, &::after {
            display: block;
            content: "";
            background-color: currentcolor;
            transition: .5s ease .1s;
          }

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 12px;
            height: 2px;
            margin: auto;
          }

          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 2px;
            height: 12px;
            margin: auto;
          }
        }
      }

      & + ul {
        --transition-time: 1s;
        display: none;
      }

      &.is-open, &.is-active {
        color: var(--red);
      }

      &.is-open {
        & span:nth-of-type(2)::after {
          opacity: 0;
          height: 0;
        }

        & + ul {
          display: block;
        }
      }
    }

    & .navigation__sidebar--dropdown {
      background-color: #f9f9f9;

      & a {
        white-space: nowrap;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: solid 1px #eee;
        padding-inline-start: 21px;
        padding-inline-end: 7px;
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        font-weight: 300;
        position: relative;
        text-decoration: none;
        color: #878787;
      }
    }
  }


}