html, html *, html *::before, html *::after {
  box-sizing: border-box;
  outline: none;
  transition: var(--transition) var(--transition-time) var(--transition-prop);
}

body {
  font-family: Poppins, sans-serif;
  text-rendering: optimizeLegibility;
  max-width: 100%;
  overflow-x: hidden;
  color: var(--black);
  min-width: var(--row-min-width);

  &:has(.flyout.is-open),
  &:has(.modal.is-open),
  &:has(.search.is-open) {
    overflow: hidden;
  }
}

@media (hover: none) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 10px;
  }

  ::-webkit-scrollbar:horizontal {
    /*height: 10px;*/
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--red);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #ffffff;
  }
}


main {
  & > .alert {
    display: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab", sans-serif;
}

h4, h5, & h6 {
  font-size: 18px;
}

& h1, & h2, & h3, & h4, & h5, & h6, strong, b, th {
  font-weight: 500;
}

img {
  max-width: 100%;
  height: auto;
  user-select: none;
}

svg {
  --transition-time: .01s;
  fill: currentColor;
}

iframe {
  width: 100%;
  max-width: 100%;

  &[title="YouTube video player"] {
    aspect-ratio: 16/9;
  }
}

ul, ol, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

input, button {
  border: 0;
}

.hide {
  display: none;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.block {
  display: block;
}

.w100 {
  width: 100% !important;
  flex: 1 1 100% !important;
  max-width: 100% !important;
}

.text-right {
  text-align: right
}

.row-small {
  max-width: var(--row-small);
}

@media (width <= 480px) {
  .small-hide {
    display: none !important
  }
}

@media (width >= 481px) and (width <= 780px) {
  .medium-hide {
    display: none !important
  }
}

@media (width > 780px) {
  .large-hide {
    display: none !important
  }
}

@media (width > 1280px) {
  .xlarge-hide {
    display: none !important
  }
}

@media (width <= 480px) {
  .small-show {
    display: block !important
  }
}

@media (width >= 481px) and (width <= 780px) {
  .medium-show {
    display: block !important
  }
}

@media (width > 780px) {
  .large-show {
    display: block !important
  }
}

@media (width > 1280px) {
  .xlarge-show {
    display: block !important
  }
}

/* global styles ***************************************************** */

html#oxid-2 .formular__field--phone {
  display: none;
}

.recommendations > .headline {
  margin-bottom: 40px;
}

[data-modal], [onclick] {
  cursor: pointer;
}

#backtotop {
  z-index: 200;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 22px;
  bottom: 80px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: white;
  border: solid 1px #eee;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  pointer-events: none;
  cursor: pointer;
  display: none;

  @media (width > 780px) {
    bottom: 110px;
  }

  &.is-show {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    pointer-events: auto;
  }

  & svg {
    transform: rotate(180deg);
  }
}

[id^="trustbadge-container"] {
  z-index: 100 !important;

  & * {
    transition: none;
  }

  & > div > div[id^="minimized-trustbadge"] {
    display: flex !important;
    width: auto !important;
    transform: translateY(-7px);

    @media (width > 780px) {
      transform: translateY(34px);
    }

    & > div {
      &:nth-of-type(1) {
        padding: 3px 7px 0 10px !important;
        border-right: 1px solid #efefef !important;

        & img {
          width: 42px !important;
          height: 42px !important;
        }

        & > p {
          display: none !important;
        }
      }

      &:nth-of-type(3) {
        padding: 0 10px 0 5px !important;

        & > p:nth-last-of-type(1) {
          font-size: 12px !important;
        }
      }

      &:empty {
        display: none !important;
      }
    }
  }
}

body:has(.header__checkout) [id^="trustbadge-container"] {
  display: none;
}

& *:has(>.read-more) {
  position: relative;
}

& a:has(.read-more):hover .read-more {
  opacity: .9;
}

& .read-more {
  position: absolute;
  top: 25px;
  left: 25px;
  bottom: 25px;
  right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 25px 20px;
  background-color: var(--red);
  color: white;
  opacity: 0;
  font-weight: 400;
}


& .info-btn-area {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;


  & div, & span {
    position: relative;
    text-align: center;
    padding: 2px 3px 2px 7px;
    font-family: Poppins, sans-serif;
    font-size: 11px;
    letter-spacing: 1.8px;
    color: white;
    line-height: 1.4;
    width: max-content;
    font-weight: 500;
    text-transform: uppercase;

    @media (width > 780px) {
      line-height: 2;
      height: 26px;
      padding: 2px 10px;
      letter-spacing: 1px;
    }


    &::before, &::after {
      display: none;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 13px 10px 0 10px;
      border-color: var(--red) transparent transparent transparent;
      position: absolute;
      right: 0;
      transform: translateX(50%);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
      transform: translateX(50%) rotate(180deg);
    }

    &.red {
      background-color: var(--red);
      margin-bottom: 7px;
    }

    &.green {
      background-color: var(--blue);
      margin-bottom: 7px;

      &::before, &::after {
        border-color: var(--blue) transparent transparent transparent;
      }
    }

    &.individual {
      background-color: #999;
      margin-bottom: 7px;

      &::before, &::after {
        border-color: #999 transparent transparent transparent;
      }
    }
  }
}

.orline {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 14px 0;
  color: #999;
  font-size: 14px;

  &::before {
    position: absolute;
    top: 49%;
    right: 0;
    left: 0;
    display: block;
    z-index: 1;
    width: 100%;
    height: 1px;
    background-color: #ccc;
    content: "";
  }

  & span {
    position: relative;
    z-index: 2;
    padding: 0 7px;
    background-color: white;
  }
}


& .sendAjax {
  position: relative;
  pointer-events: none;

  &::before, &::after {
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
  }

  &::after {
    background: url("/out/wallart/img/appicons/2/appicon-192x192.png") no-repeat center center;
    background-size: 40px;
    z-index: 21;
    animation: loadingIcon 2s linear infinite;
  }

  &.sendAjaxSuccess::after {
    background-image: url("/out/wallart/img/svg/check-circle.svg");
    animation: none;
  }
}

[disabled] {
  opacity: .75;
  cursor: auto;
  pointer-events: none;
}

[data-animate]:not(.animate__animated) {
  opacity: 0;
}

main:not(.thankyou) ~ [id^="trustbadge-container"] > div > div[id^="minimized-trustbadge"],
main:not(.thankyou) ~ [id^="trustbadge-container"] > div > div[id^="maximized-trustbadge"] {
  right: auto !important;
  left: 20px !important;
}

#oxbotfriendbtn {
  @media (width <= 780px) {
    bottom: 20px !important;
  }
}

#web-messenger-container:not(.open) {
  width: 70px;
  height: 80px;
  z-index: 100 !important;
  @media (width <= 780px) {
    bottom: 0 !important;
  }
}

#web-messenger-container.open {
  z-index: 220;
}

@keyframes loadingIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



