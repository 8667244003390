.basket {

  & .basket__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 14px 0 14px;
    margin-bottom: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120% auto;
    font-family: Poppins, sans-serif;

    & h1 {
      margin: 0;
      text-align: center;
      font-family: Poppins, sans-serif;
      font-size: 7vw;
      font-weight: 500;

      @media (width > 480px) {
        font-size: 32px;
      }
    }

    &[style*="background-image"] {
      padding-top: 60px;
      padding-bottom: 60px;
      border-bottom: 1px solid var(--grey);

      & h1, & .breadcrumb * {
        color: white;
      }
    }

  }

  & .basket__row {
    --row-max-width: 1400px;
  }

  & .basket__list {
    width: 100%;
    margin-bottom: 30px;
    border-collapse: collapse;
    border-spacing: 0;
    line-height: 1.4;

    & thead {
      @media (width < 780px) {
        display: none;
      }
    }

    & tr {
      border: 1px solid var(--grey);

      @media (width <= 780px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    & th {
      padding: 10px 25px;
      border: 0;
      color: var(--dark);
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 2.5px;

      @media (width < 780px) {
        display: none;
      }

      &:first-child {
        text-align: start;
      }
    }

    & td {
      vertical-align: middle;
      padding: 10px;
      color: var(--grey-text);
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;

      @media (width > 780px) {
        padding: 25px 10px;
      }

      &:nth-of-type(1) {
        @media (width > 780px) {
          padding-inline-start: 25px;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        @media (width <= 780px) {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          align-items: center;
        }
      }

      & img {
        min-width: 100px;
      }

      & a, & > img {
        display: block;
        margin-right: 20px;
        margin-bottom: 7px;
        color: var(--dark);
        text-decoration: none;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;

        & ~ *:not(input) {
          font-size: 14px;
          line-height: 1.6;
        }
      }
    }

    & [name="removeBtn"] {
      background-color: transparent;
      color: var(--red);
    }

    & .quantity-wrapper {
      position: relative;
      display: flex;
      width: 140px;
      height: 50px;
      min-width: 120px;
      background-color: rgba(238, 238, 238, .35);
      border: 1px solid rgba(238, 238, 238, .9);
      text-align: center;
      margin-inline-end: 15px;

      & > div {
        display: flex;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;

        &:hover {
          color: var(--red);
        }

        &:nth-of-type(1) {
          & svg {
            transform: rotate(90deg);
          }
        }

        &:nth-of-type(2) {
          & svg {
            transform: rotate(-90deg);
          }
        }
      }

      & input {
        flex-grow: 1;
        width: 50px;
        height: 48px;
        padding: 0;
        background: 0 0;
        border: 0;
        text-align: center;
        font-size: 18px;
        font-weight: 500;

      }

      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }
    }
  }

  & .basket__sidebar {
    position: sticky;
    top: 20px;
    height: max-content;
    padding: 20px;
    background-color: var(--light-grey);
    border: 1px solid var(--grey);

    @media (width > 780px) {
      margin-left: 5%;
    }

    & .orline span {
      background-color: var(--light-grey);
    }

    & > .basket__row > .grid-column:nth-of-type(1) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    & .footer {
      & .footer__page {
        background-color: transparent;
        color: inherit;

        & .footer__payment {
          margin-top: 30px;
          margin-bottom: 0;

          & .payment-info {
            & a {
              width: 55px;
              margin-right: 0;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  & .voucher-label {
    color: var(--dark);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;

    & small {
      display: block;
      margin-top: 3px;
      color: var(--grey-text);
      text-transform: none;
      font-size: 16px;
      font-weight: 300;
    }

    & + .input-group {
      display: flex;

      & button {
        min-height: auto;
      }
    }
  }

  & #basketSummary {
    display: flex;
    justify-content: flex-end;

    & table {
      width: 100%;
    }

    & th {
      padding: 3px 0;
      color: var(--grey-text);
      text-align: left;
      font-size: 16px;
      font-weight: 400;
    }

    & td {
      text-align: right;
    }

    & strong {
      color: var(--dark);
      font-size: 18px;
      font-weight: 600;
    }


    & #productPriceMwst {
      font-size: 11px;
      display: flex;
      justify-content: flex-end;
      color: var(--grey-text);
    }
  }

  & .basket__shipping {
    margin-bottom: 20px;

    & .basket__shipping--text {
      color: var(--grey-text);
      font-size: 14px;

      & b {
        font-weight: 500;

        &:nth-of-type(1) {
          color: var(--red);
        }

        &:nth-of-type(2) {
          color: var(--dark);
        }

        &.green {
          color: var(--green-dark);
        }
      }
    }

    & .basket__shipping--progressbar {
      position: relative;
      display: block;
      height: 12px;
      margin-top: 10px;
      box-shadow: inset 0 1px 2px #0000001a;
      background-color: rgb(255, 232, 232);
      border-radius: 5px;

      &.is-free {
        background-color: transparent;

        & > div {
          background-color: var(--green-dark);
          color: var(--green-dark);
        }
      }

      & > div {
        position: relative;
        display: flex;
        justify-content: flex-end;
        height: 12px;
        background-color: rgb(247, 107, 106);
        background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
        background-size: 40px 40px;
        border-radius: 5px;
        color: var(--red);
        animation: 2s linear infinite progress_bar;
        transition: width 1s;

        & > svg {
          transform: translate(70%, -10%);
        }
      }
    }

  }

  & .basket__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 28px;

    & .paypalExpressCheckoutBox {
      min-height: 55px;

      @media (width <= 780px) {
        margin-bottom: 15px !important;
      }
    }

    & > * {
      @media (width <= 780px) {
        width: 100%;
      }
    }
  }

  & .basket__next {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    & button {
      justify-content: center;
      width: 100%;
      min-width: 320px;
      font-size: 16px;
      letter-spacing: 2.4px;
    }
  }

  & .basket__express {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div ~ .orline {
      display: flex;
      order: -1;
    }

    & .orline {
      display: none;
    }

    & .payWithAmazonButton {
      position: relative;
      float: none;
      width: 100%;
      min-height: 48px;
      margin: 0 auto;
      background: url(/out/wallart-dm/img/payment/amazon_pay.svg) no-repeat center 44% #6d737a;
      background-size: 120px auto;
    }
  }

  /* flyout styles */

  &.basket__flyout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }


    & .basket__shipping {
      padding: 21px 14px;
      box-shadow: 0 0 10px 0 rgba(238, 238, 238, .7);
      border-bottom: 1px solid rgba(238, 238, 238, .7);

      & .basket__shipping--progressbar {
        width: 100%;
      }
    }

    & .basket__products {
      flex: 1 1 auto;
      height: 0;
      overflow-y: auto;
    }

    & .basket__product {
      display: flex;
      padding: 15px;
      border-bottom: 1px solid var(--grey);

      & > img {
        display: block;
        max-width: 100px;
        margin-right: 20px;
      }

      & .basket__product--title {
        display: block;
        display: -webkit-box;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      & .basket__product--price {
        color: var(--grey-text);
        font-size: 14px;
      }
    }

    & .basket__total {
      padding: 26px;
      box-shadow: 0 0 10px 0 rgba(238, 238, 238, .7);
      border-bottom: 1px solid rgba(238, 238, 238, .7);

      & > div {
        &:nth-of-type(1) {
          display: flex;
          justify-content: space-between;
          font-weight: 500;
        }
      }
    }

    & .basket__buttons {
      display: flex;
      flex-direction: column;

      & a {
        display: flex;
        justify-content: center;
        width: 100%;

        &:hover {
          background-color: var(--red);
          border-color: var(--red);
        }

        &:nth-last-of-type(1) {
          margin-top: 16px;
        }
      }
    }
  }

  & .basket__bar {
    position: fixed;
    right: 0;
    bottom: -130px;
    left: 0;
    z-index: 1000;
    padding: 14px;
    box-shadow: 0 -5px 20px rgba(0, 0, 0, .16);
    background: #fff;
    will-change: top;

    & > ul {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

@keyframes progress_bar {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
