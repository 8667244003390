.button {
  --transition-time: .1s;
  --fs: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 30px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: var(--fs);
  min-height: 55px;
  width: max-content;
  cursor: pointer;

  &:hover > svg {
    position: relative;
    left: 3px;
  }

  & > svg {
    margin-inline-start: 4px;
    fill: currentcolor;
  }

  &.button__medium {
    --fs: 14px;
  }
  &.button__big {
    --fs: 16px;
  }

  &.button__white {
    color: white;
    border: 2px solid white;
    background-color: transparent;
  }

  &.button__red {
    color: white;
    background-color: var(--red);
  }

  &.button__green {
    color: white;
    background-color: var(--green-dark);
    &:hover {
      background-color: var(--green);
    }
  }

  &.button__dark {
    color: var(--dark);
    border: 2px solid var(--dark);
    background-color: transparent;

    &:hover {
      color: white !important;
      background-color: var(--dark);
      border-color: white;
    }
  }

  &.button__black {
    color: white;
    background-color: var(--dark);

    &:hover {
      background-color: var(--red);
    }
  }
}