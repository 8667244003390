.motivgroup {

  & .motivgroup__teaser {
    margin-top: 10px;
    margin-bottom: 21px;

    & .button {
      position: relative;
      justify-content: center;

      & span {
        --transition-time: .005s;
        display: inline;
      }

      & > div {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        background-color: var(--dark);
        border-radius: 50%;
        color: white;
        font-family: Poppins, sans-serif;
        font-size: 11px;
        line-height: 14px;
        transform: translate(-100%, -50%);
        aspect-ratio: 1;
      }
    }
  }
}