.newsletter {

  &:not(.page) {
    background-color: var(--dark);

    & > div {
      margin: 50px auto;
      max-width: 90%;

      @media (width > 780px) {
        max-width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > div {
        @media (width > 780px) {
          flex: 0 0 50%;
          padding: 0 10px;
        }
      }


      & h4 {
        margin: 0 0 18px 0;
        font-family: Oswald, sans-serif;
        font-size: 40px;
        color: white;
        font-weight: 400;
      }


      & p {
        color: white;
        font-size: 18px;
        line-height: 1.8;
        margin: 0;
      }

      & .newsletter__input > form {
        display: flex;
        align-items: stretch;
      }

      [type=email] {
        width: 100%;
        height: 60px;
        padding-inline-start: 27px;
        border: 0;
        background-color: white;
      }

      [type=submit] {
        --transition-time: .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 2px solid white;
        height: 60px;
        aspect-ratio: 1;
        color: var(--red);

        &:hover {
          cursor: pointer;
          background-color: var(--red);
          color: white;
        }
      }

      & .newsletter__footnote {
        color: white;
        display: block;
        margin-bottom: 7px;
      }

    }

    & .newsletter__discount {
      width: 90px;
      aspect-ratio: 1;
      background-color: var(--red);
      color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 34px;
      font-weight: 700;
      position: absolute;
      right: 0;
      top: -90px;
      transform: rotate(10deg);
      border: 2px solid white;

      @media (width > 780px) {
        border-width: 5px;
      }

      @media (width > 1280px) {
        border-width: 5px;
        top: -30px;
        right: 30px;
      }
    }
  }
}