.formular {

  & .formular__search {
    & button {
      position: absolute;
      right: 8px;
      top: 8px;
      background-color: white;
      pointer-events: none;
    }
  }

}