.rating {

  &.oxwarticledetails:hover {
    cursor: pointer;
    & .rating__count {
      text-decoration: underline;
    }
  }

  & .rating__list {
    position: relative;

    & .rating__current--svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & .rating__count {
    font-size: 14px;
    position: relative;
    top: 2px;
    margin-inline-start: 5px;
    color: var(--grey-text);
  }

  & .ratings {
    padding: 24px;
    border: 1px solid #ccc;
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;

    & > [itemprop="review"] {
      padding-bottom: 24px;
      border-top: 1px solid transparent;
      font-size: 14px;
      line-height: 21px;
      color: var(--grey-text);

      &:not(:nth-of-type(1)) {
        padding-top: 24px;
        border-color: #ECECEC;
      }
    }

    & > [id^=reviewName]:not(:last-child){
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }
  }

  & .rating__new {
    & .rating__new--headline {
      color: var(--dark);
    }
  }
}