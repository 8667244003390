.uploader {
  & .uploader__row {
    --row-max-width: 98%;

    @media (width > 780px) {
      --row-max-width: 84%;
    }
  }


  & .uploader__siblings {
    display: flex;
    overflow-x: auto;

    @media (width > 780px) {
      padding: 0 30px 0 0;
      flex-direction: column;
    }

    & a {
      display: flex;
      flex-direction: column;
      color: var(--grey-text);
      text-transform: uppercase;
      margin-bottom: 18px;
      text-decoration: none;
      margin-right: 30px;

      @media (width > 780px) {
        margin-right: 0;
      }

      &:hover {
        & {
          color: var(--red);
        }

        &::after {
          background-color: var(--red);
        }
      }

      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background: var(--grey);
        margin-top: 18px;
      }

      & span {
        display: flex;
        align-items: center;

        & img {
          aspect-ratio: 1;
          max-width: 60px;
          border-radius: 50%;
          margin-right: 30px;
        }
      }
    }
  }

  & .uploader__categorys {
    & a {
      text-decoration: none;
      color: inherit;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 2px solid var(--light-grey);

      @media (width > 780px) {
        flex-direction: row;
      }

      &:hover {
        border-bottom-color: var(--red);
      }

      &:nth-of-type(even) {
        @media (width > 780px) {
          flex-direction: row-reverse;
        }

        & > div {
          &:nth-of-type(1) {
            @media (width > 780px) {
              margin-right: 0;
              margin-left: 30px;
            }
          }
        }
      }

      & > div {
        &:nth-of-type(1) {
          min-width: min-content;
          @media (width > 780px) {
            margin-right: 30px;
          }

          & > img {
            min-width: 370px;
          }
        }
      }

      & h6 {
        font-size: 16px;
        margin-bottom: 0;
      }

      & h3 {
        font-size: 34px;
        margin-bottom: 0;
      }

      & p {
        color: var(--grey-text);
        font-weight: 300;
        line-height: 30px;
      }
    }
  }

  & .uploader__button {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (width < 780px) {
      order: 2;
      margin-top: 30px;
    }

  }

  & .uploader__header {

    & h1 {
      font-family: "Roboto Slab", sans-serif;
      font-size: 14px;
      margin-bottom: 10px;
      font-weight: 500;
      letter-spacing: 2px;
      text-align: center;

      @media (width > 780px) {
        text-align: left;
        font-size: 16px;
      }
    }

    & .headline__text {
      font-family: "Roboto Slab", sans-serif;
      font-size: 24px;
      margin: 0;
      font-weight: 500;
      text-align: center;

      @media (width > 780px) {
        font-size: 35px;
        text-align: left;
      }
    }

    & > p {
      font-size: 20px;
      font-weight: 300;

      margin-top: 16px;
      margin-bottom: 0;
      text-align: center;

      @media (width > 780px) {
        text-align: left;
        font-size: 30px;
        line-height: 50px;
      }
    }
  }

  & .uploader__bullets {
    --row-max-width: 94%;
    background-color: var(--dark);
    position: relative;

    & > .grid-column {
      &:nth-of-type(1) {

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          content: "";
          display: block;
          height: 30%;
          background-color: white;
        }

        text-align: right;

        & img {
          position: relative;
          z-index: 2;
          margin-right: 20px;
          max-width: 600px;
        }
      }

      &:nth-of-type(2) {
        color: white;

        & li {
          padding: 5% 4% 4% 3%;

          & b {
            font-size: 35px;
            font-weight: 400;
            font-family: "Roboto Slab", sans-serif;
          }

          & p {
            font-weight: 300;
          }
        }
      }

    }
  }

  & .uploader__material {
    --row-max-width: 980px;

    & .column-left {
      & > div {
        max-width: 80%;
        display: flex;
        flex-direction: column;
        color: var(--grey-text);
        text-transform: uppercase;
        text-decoration: none;

        &::before {
          content: "";
          width: 100%;
          height: 2px;
          background: var(--red);
          margin-bottom: 18px;
        }
      }

    }

    & .column-right {
      & .uploader__properties {
        line-height: 35px;
        font-size: 35px;
        font-weight: 400;
        font-family: "Roboto Slab", sans-serif;
        margin-bottom: 20px;
      }

      & ul {
        margin-left: 20px;
      }

      & li {
        list-style-type: disc;
        color: var(--grey-text);
        font-weight: 300;
        line-height: 34px;
      }

      & li::marker {
        color: var(--red);
      }

      & p {
        color: var(--grey-text);
        font-weight: 300;
        line-height: 30px;
      }
    }
  }

  & .uploader__images {
    max-width: 94%;
    margin: 0 auto;
    display: flex;

    & iframe {
      aspect-ratio: 16/11.4;
      @media (width > 780px) {
        padding: 20px;
      }
    }

    & img {
      width: 100%;

      @media (width > 780px) {
        padding: 20px;
      }
    }

  }
}