.user {
  & .user__button {
    width: 100%;
    justify-content: center;
    max-width: 480px;
    margin-bottom: 7px;

     @media (width > 1280px) {
        margin-top: 21px;
     }

    & + .card {
      padding-bottom: 21px;
      border-bottom: 1px solid var(--grey);
      @media (width <= 780px) {
        display: none;
      }
    }
  }

  & .user__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 14px 0 14px;
    margin-bottom: 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120% auto;
    font-family: Poppins, sans-serif;

    & h1 {
      margin: 0;
      font-size: 7vw;
      font-weight: 500;
      text-align: center;
      font-family: Poppins, sans-serif;

      @media (width > 480px) {
        font-size: 32px;
      }
    }

    &[style*="background-image"] {
      padding-top: 60px;
      padding-bottom: 60px;
      border-bottom: 1px solid var(--grey);

      & h1, & .breadcrumb * {
        color: white;
      }
    }
  }


  & .column-left {
    @media (width > 1280px) {
      padding-right: 50px;
    }

  }

  & .column-right {
    padding: 0 10px 30px 10px;
    background-color: var(--light-grey);
    margin-top: 20px;

    @media (width > 1280px) {
      border: 1px solid var(--grey);
      margin-top: 0;
      padding: 0 30px 30px 30px;
    }
  }

  & select {
    width: 100%;
  }

  & #shippingAddressForm {
    display: none;
  }

  & .tooltip {
    display: none;
  }

  & #userNextStepBottom {
    font-size: 16px;
  }

  & .grid-columns:has(#userNextStepBottom),
  & ul > li:has(#userNextStepBottom) {
    display: flex;
    justify-content: flex-end;
  }

  & [for="orderRemark"] {
    &, & + div {
      display: none;
    }
  }

  & [name="blshowshipaddress"] {
    & + .checkoutCollumns {
      & > ul:nth-of-type(1) {
        & > li:nth-of-type(1) {
          @media (width > 780px) {
            border-right: 1px solid #d4d4d4;
          }

          & .card-title {
            display: flex;
            justify-content: space-between;

            & button {
              font-family: Poppins, sans-serif;
              font-size: 14px;
              padding: 7px 14px;
              margin-right: 14px;
            }
          }
        }
      }
    }
  }

  & form[name=order] {

    & .formular__order.shipping {
      border-bottom: 1px solid #bbb;
    }

    & .salutation {
      display: none;
    }

    & .formular__field:has(:is([type=text],[type=password],[type=email])):not(:has(select)) {

      &:has(:is(input:not(:placeholder-shown), input:focus)) {
        & label {
          top: 10px;
          font-size: 12px;
          color: var(--grey-text);
        }
      }

      & input {
        padding: 28px 14px 10px 14px;
        height: auto;
      }

      & label {
        --transition-time: .15s;
        position: absolute;
        pointer-events: none;
        left: 14px;
        top: 18px;
        margin-bottom: 0;
      }
    }
  }
}