:root {
  --row-max-width: 100%;
  --row-min-width: 320px;
  --row-small: 1170px;

  --red: #AF2020;
  --red-rgb: 175,32,32;
  --red-critical: rgb(221, 29, 29);
  --black: #0A0A0A;
  --dark: #293341;
  --grey: #eee;
  --grey-text: #777;
  --light-grey: #f5f5f5;
  --blue: #0774d7;
  --green: #43AB32;
  --green-dark: #428445;
  --yellow: #e0b252;

  --grid-space: 10px;
  --block-grid-space: 7px;
  --primary-line-height: 1.6;

  --transition: all;
  --transition-time: .5s ;
  --transition-prop: linear;

  accent-color: var(--red);

  @media (width > 780px) {
    --block-grid-space: 14px;
  }

}