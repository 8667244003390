/* styles dropdowwn */
[data-dropdown] {
  --transition-time: .2s;
  position: relative;

  & [data-dropdown-opener] {
    --bg-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--bg-color);

    & > * {
      user-select: none;
      pointer-events: none;
    }

    &.open ~ [data-dropdown-list] {
      display: flex;
    }

    &:hover {
      color: var(--red);
    }

    & img[src*=lang] {
      position: relative;
      top: -1px;
      display: inline-block;
      margin-right: 4px;
    }


    & svg {
      --transition-time: .25s;
      margin-left: 7px;
      transform: rotate(0deg);
    }

    &.open svg {
      transform: rotate(180deg);
    }
  }

  & [data-dropdown-list] {
    position: absolute;
    top: calc(100% + 16px);
    right: 0;
    display: none;
    z-index: 10000;
    flex-direction: column;
    max-height: 400px;
    min-width: 160px;
    padding: 5px 0 2px 0;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    background-color: white;
    color: var(--black);
    scrollbar-width: thin;
    overflow-y: auto;

    &::before {
      position: absolute;
      top: -5px;
      right: 10px;
      display: flex;
      z-index: -1;
      width: 10px;
      height: 10px;
      background-color: white;
      transform: rotate(45deg);
      transform-origin: center;
      content: "";
    }

    & li {
      padding: 2px 0;
      border-bottom: 4px solid white;
      user-select: none;
      color: #7b7b7b;

      &:hover, &.active {
        background: rgba(var(--red-rgb), 0.08);
        color: var(--red);
      }

      &:hover {
        cursor: pointer;
        color: var(--red);
      }

      & a {
        --transition-time: .01s;
        display: flex;
        align-items: center;
        padding: 7px 14px;
        user-select: none;
        color: currentColor;
        text-decoration: none;
        font-weight: 300;
        white-space: nowrap;


        & > * {
          user-select: none;
          pointer-events: none;
        }

        & b {
          font-weight: 500;
        }
      }
    }
  }

  /* currency styles */

  & img[src*=flags] {
    margin-right: 7px;
  }
}


/* style forms */
.form-group,
.formular__field {
  margin-bottom: 18px;
  position: relative;

  & label {
    margin-bottom: 8px;
    display: block;
    font-size: 16px;
    color: var(--dark);

    & .required {
      color: var(--red);
      font-size: 16px;
      margin-inline-start: 3px;
      line-height: 1;
    }
  }
}


#forgotPasswordLink, #openAccountLink {
  text-decoration: none;
  color: var(--grey-text);
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.formular__headline {
  position: relative;
  margin: 0 0 14px 0;
  font-size: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & > div {
    font-weight: 500;

    & small {
      font-weight: normal;
    }
  }

  &.spacer {
    margin-top: 26px;
  }

  & .tooltip {
    margin: 0 5px;
  }

  & small {
    color: #999;
  }

  & .formular_required {
    font-size: 13px;
  }

  & .formular__hint {
    cursor: auto;

    &.packstation {
      text-align: right;
      color: var(--red);
    }

    & > img {
      display: none;
    }
  }
}

.formular__checkbox {
  & > div {
    display: flex;
    align-items: flex-start;

    & > label {
      transform: translateY(-6px);
    }
  }
}

input:is([type="text"],[type="email"],[type="password"], [type="search"],[type="tel"]) {
  width: 100%;
  height: 50px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: none;
  appearance: none;
  border: 1px solid #d4d4d4;
  background-color: white;
  padding: 7px 14px;
  
  &:required:focus:invalid {
    border-color: var(--red);
  }
}

textarea {
  width: 100%;
  line-height: 20px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: none;
  appearance: none;
  border: 1px solid #d4d4d4;
  background-color: white;
  padding: 7px 14px;
}

select {
  cursor: pointer;
  width: max-content;
  height: 50px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: none;
  appearance: none;
  border: 1px solid #d4d4d4;
  background-color: white;
  padding: 7px 54px 7px 14px;
  background-image: url("/out/wallart/img/svg/arrow-bottom.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: right 14px center;
}

input:is([type="radio"], [type="checkbox"]) {
  display: inline-block;
  margin-right: 7px;
  cursor: pointer;

  & + label {
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
    max-width: 90%;
  }
}

label[for="blnewssubscribed_noaccount"] {
  font-size: 13px;
}

[data-status="invalid"] {
  & [hidden] {
    display: block;
    font-size: 12px;
    color: var(--red-critical);
    margin-top: 4px;

    & > .alert {
      display: none;
    }
  }

  & input {
    border-color: var(--red-critical);
  }
}

