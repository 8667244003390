.header {

  &:not(.header__checkout) {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: white;
    min-height: 55px;
  }

  &.header__checkout {
    & .grid-column:has(.header__logo) {
      justify-content: center !important;
      padding: 10px;
      @media (width > 780px) {
        padding: 28px 28px 15px 28px;
      }
    }

    & .header__main > div {
      background-color: white;
    }

    & .header__logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (width > 780px) {
        flex-direction: row;
        align-items: baseline;
      }
    }
  }

  & .header__top {
    --transition-time: .001s;
    padding-top: 10px;
    padding-bottom: 3px;
    background-color: white;
    color: var(--dark);


    @media (width > 780px) {
      background-color: var(--dark);
      color: white;
      padding-top: 7px;
      padding-bottom: 7px;
    }

    & .header__left {
      padding-right: 0;

      & .header__menu {
        font-size: 14px;
        text-transform: uppercase;

        & .header__menu--item:not(:last-of-type) {
          margin-right: 7px;
        }
      }
    }

    & .header__middle {
      & .header__trusted {
        display: none;
        align-items: center;
        font-size: 14px;
        text-transform: uppercase;

        @media (width > 780px) {
          display: flex;
        }

        & a:hover span {
          border-color: white;
        }

        & > img {
          margin-right: 7px;
        }

        & span {
          display: flex;
          align-items: center;
          border-bottom: 1px solid transparent;
        }

        & .rating {
          margin: 0 4px;
          position: relative;
          top: .5px;
        }

        & .rating__list {
          width: 75px;
        }
      }
    }

    & .header__right {

      & > ul {
        display: flex;
      }
    }

    & > .grid-row {
      @media (width > 1280px) {
        --row-max-width: 96%;
      }
    }

    & > .grid-row > .grid-column {
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        justify-content: center;
      }

      &:nth-of-type(3) {
        justify-content: flex-end;
      }
    }

    & a {
      color: inherit;
      text-decoration: none;
    }

    & .header__currency {
      margin-right: 4px;
      position: relative;
      top: -2px;

      @media (width > 780px) {
        margin-right: 10px;
        top: 0;
      }

      & [data-dropdown] {
        --transition-time: .00001s;

        & [data-dropdown-opener] {
          --bg-color: transparent;
          color: var(--dark);
          font-size: 12px;
          @media (width > 780px) {
            color: white;
          }
        }
      }
    }
  }

  & .header__logo {
    & > a {
      display: block;
      color: var(--dark);
      max-height: max-content;
    }
  }

  & .header__main {
    border-bottom: 1px solid var(--grey);

    & .header__logo {
      margin-right: 14px;

      @media (width > 1024px) {
        margin-right: 0;
      }
    }

    & > div {
      display: flex;
      align-items: center;
      padding: 12px 10px;
      background-color: var(--light-grey);
      margin: 0 auto;


      @media (width > 780px) {
        background-color: white;
      }

      @media (width > 1024px) {
        padding-top: 0;
        padding-bottom: 0;
      }

      @media (width > 1280px) {
        max-width: 96%;
      }
    }


    & .header__navigation {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }

    & .header__logo a {
      display: flex;

      & svg {
        @media (width > 1280px) {
          width: 170px;
          height: 37px;
        }
      }
    }

    & .header__search {

      @media (width <= 1024px) {
        width: 100%;
      }
      @media (width > 1024px) {
        padding: 0;
      }

      & [type="search"] {
        height: 40px;
      }
    }
  }

}