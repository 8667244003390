.headline {
  margin: 0 auto;

  & .headline__text {
    font-family: Oswald, sans-serif;
    margin-top: 0;
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    text-rendering: geometricPrecision;
    position: relative;
    letter-spacing: 5px;
    margin-bottom: 21px;

    @media (width > 780px) {
      font-size: 26px;
    }

    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background-color: var(--grey);
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 1;
    }

    & span {
      background-color: white;
      padding: 0 15px;
      position: relative;
      z-index: 2;
    }
  }
}