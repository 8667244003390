.thankyou {

  & .checkout__overview {
    border: 1px solid #d4d4d4;
    background-color: rgba(238, 238, 238, 0.35);
    margin: 0;
  }

  & .headline span {
    display: block;
    text-align: center;
  }
}