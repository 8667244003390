.favourites {
  & .favourites__list {
    & li {
      position: relative;
    }
    & a {
      text-decoration: none;
      color: var(--grey-text);
    }
    &:not(:empty) {
      padding: 30px 0;
      & + p {
        display: none;
      }
    }
    & [data-delete] {
      border: 0;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(25%,-25%);
      z-index: 2;
      transition: transform .15s ease-out;
      width: 30px;
      height: 30px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--red);
      color: white;
      cursor: pointer;
    }
  }
}