.modal {
  --transition-time: .5s;
  display: flex;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9997;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;

  &.is-open {
    opacity: 1;
    pointer-events: auto;

    & .modal__bg {
      opacity: 1;
      pointer-events: auto;
    }
    & .modal__inner {
      transform: translateY(0);
    }
  }

  & .modal__bg {
    --transition-prop: ease-in-out;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    animation: none;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
  }

  & .modal__inner {
    --transition: transform;
    --transition-prop: ease-in-out;
    position: relative;
    z-index: 2;
    background-color: #fff;
    width: 92%;
    height: max-content;
    max-height: 92%;
    margin: 15px;
    display: flex;
    justify-content: center;
    transform: translateY(-50%);

    @media (width > 780px) {
      box-shadow: 0 12px 25px #00000026;
      width: 100%;
      max-width: 1000px;
    }
  }

  & .modal__content {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--grey);
    padding: 21px;

    &:empty {
      min-height: 50vh;
      overflow: hidden;
      &::before {
        content: "";
        display: flex;
        background: url("/out/wallart/img/appicons/2/appicon-192x192.png") no-repeat center center;
        background-size: 40px;
        z-index: 21;
        animation: loadingIcon 2s linear infinite;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  & .modal__close {
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    transition: transform .15s ease-out;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dark);
    color: white;
    cursor: pointer;
    transform: translate(25%,-25%);

    &:hover {
      background-color: var(--red);
    }
  }
}