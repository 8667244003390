.register {

  & .register__form {
    padding: 0 10px 30px 10px;
    background-color: var(--light-grey);
    margin-top: 20px;

    @media (width > 1280px) {
      border: 1px solid var(--grey);
      margin-top: 0;
      padding: 0 30px 30px 30px;
    }

    & select {
      width: 100%;
    }

    & .formular__field--phone {
      & .tooltip__text {
        margin-left: 7px !important;
        font-size: 11px;
      }
    }
  }

}

