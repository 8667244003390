.cookie {
  z-index: 250;
  position: fixed;
  top: 26%;
  left: 50%;
  width: 90%;
  max-width: 650px;
  transform: translate(-50%,0);
  transition: all 1s cubic-bezier(0, 0, 0, 1) 0s;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);

  @media (width > 780px) {
    top: 10%;
  }



  &[style*=block]  {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  &#cookieNote {
    & h4 {
      font-size: 14px;
      color: #666;
    }

    & span {
      display: block;
      font-size: 13px;
      color: inherit;
    }

    & a {
      color: inherit;
      font-size: inherit;
      font-weight: 600;
      text-decoration: underline;
    }

    &.blsetToCenter {

      & .modal-dialog {
        border: 1px solid #d4d4d4;
        background-color: rgba(238, 238, 238, 1);
        display: flex !important;
        justify-content: center;
        align-items: center;
        line-height: 1.4;
        text-rendering: geometricPrecision;
        user-select: none;
        font-size: 13px;
        max-height: 90%;
      }

      & .modal-body {
        @media (width > 780px) {
          flex-direction: column;
        }
      }

      & .cookienote-welcometext + div {
        margin: 21px 0 5px 0;

        @media (width > 780px) {
          margin-top: 10px;
        }

        & .panel-group.row {
          width: 100%;
          margin: -21px 0 14px 0;
          flex-grow: 1;
        }
      }

      & .cookie__buttons {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
      }

      & .modal-content button {
        max-width: 42%;
        display: flex;
        justify-content: center;
        padding: var(--grid-space);

        @media (width > 780px) {
          margin: 0;
          max-width: 180px;
        }

        &#saveCookieSelectionAll {
          background-color: var(--dark);
          @media (width > 780px) {
            order: 1;
          }
        }
      }
    }
  }

  & .modal-dialog {
    height: auto;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: calc(var(--block-grid-space) * 2);

    @media (width > 780px) {
      padding: calc(var(--grid-space) * 2);
    }

  }

  & .modal-body {
    width: 100%;
    max-width: var(--shop-max-width);

    @media (width > 780px) {
      display: flex;
    }

    & > div {
      min-width: 210px;
    }
  }

  & .modal-content {
    & button {
      width: 49%;
      font-size: 12px;
      text-transform: uppercase;


      @media (width > 780px) {
        margin: calc(var(--block-grid-space) * 1.6) 0;
      }
    }

    & #saveCookieSelectionAll {
      background-color: var(--color-red);
      border-color: var(--color-red);
      color: white;
      & b {
        font-weight: 600;
      }
      &:hover {
        opacity: .8;
        box-shadow: none !important;
      }
    }

    & #adjust-cookie-settings,
    & #saveCookieSelection {
      background-color: white;
      border-color: #666;
      color: var(--color-dark);
      &:hover {
        box-shadow: none;
      }
    }
  }

  & .modal-title {
    color: white;
    font-family: var(--font-raleway);
    position: relative;
    font-weight: 700;
    display: none;
  }

  & .cookienote-welcometext {
    font-family: var(--font-lato);
    font-size: 13px;
    text-rendering: geometricPrecision;
    margin: var(--block-grid-space) 0;
    max-height: 200px;
    overflow-y: auto;
    line-height: 1.6;
    color: var(--grey-text);
    @media (width > 780px) {
      max-height: 260px;
    }
  }

  & .modal-footer-additional {
    text-align: center;
    color: var(--color-dark);
    display: none;
  }

  & #cookieSelectionForm {
    display: flex;
    flex-direction: column;
  }

  & .panel {
    margin: var(--grid-space) 0;
  }

  & .panel-body {
    background-color: white;
    padding: var(--block-grid-space) calc( var(--block-grid-space)*2);
  }

  & .panel-group {
    max-height: 330px;
    overflow-y: auto;
    & .panel  {
      margin-top: 21px;

      & p {
        color: var(--grey-text);
        font-size: 13px;
      }
    }
  }

  & .panel-collapse {
    display: none;
  }

  & .panel-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & a {
      color: white;
      text-decoration: none;
    }

    & i {
      position: absolute;
      right: calc(var(--block-grid-space) * 2);
      top: 35%;

    }

    & label[for^=toggle] {
      position: relative;
      margin-right: 21px;
      & span {
        position: absolute;
        right: 120%;
        font-size: 11px;
        white-space: nowrap;
        color: white;
        font-weight: 300;
      }
    }
  }

  & .panel-body.list {
    & li {
      margin-bottom: 5px;
      position: relative;
    }

    & label {
      font-size: 13px;

      &::before {
        top: 2px;
      }
    }

    & [data-modal-url] {
      position: absolute;
      top: 0;
      right: 0;
    }

    & [type=checkbox]:disabled + label:before {
      box-shadow: none;
    }
  }


}
